import React from 'react'
import { Link } from 'react-router-dom'
import instagram from '../../../assets/icon 3d/instagram.png'
import telegram from '../../../assets/icon 3d/telegram.png'
import logo from '../../../assets/logo-vmake.jpg'
import { themes } from '../../../utils/themes'
import styles from './footer-main.module.css'
const FooterMain = () => {
	return (
		<div style={themes.container}>
			<div className={styles.main}>
				<div className={styles.logo}>
					<div className={styles.logoBlock}>
						<img className={styles.logoImg} src={logo} alt='tezjarnama' loading="lazy"/>
						<h1 className={styles.logoText}>TezJarnama</h1>
					</div>
				</div>
				<ul className={styles.links}>
					<Link to='/help'>Помощь</Link>
					<Link to='/about'>О проекте</Link>
					<Link to='/feedback'>Обратная связь</Link>
				</ul>
				<ul className={styles.message}>
					<h3 className={styles.subTitle}>Мы в социальных сетях</h3>
					<div className={styles.imgs}>
						<a target='_blank' href='https://www.instagram.com/tezjarnama.ru/'>
							<img
								className={styles.img}
								src={instagram}
								alt='tezjarnama instagram'
								loading="lazy"
							/>
						</a>
						<a target='_blank' href='https://t.me/+-Oj08IilYJViMGIy'>
							<img
								className={styles.img}
								src={telegram}
								alt='tezjarnama telegram'
								loading="lazy"
							/>
						</a>
					</div>
				</ul>
				<div className={styles.right}>
					<p></p>
				</div>
			</div>
		</div>
	)
}

export default FooterMain
