//1
import CircularProgress from '@mui/material/CircularProgress'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useResetPasswordMutation } from '../../../redux/rtk-query/user-api'
import { setStorage } from '../../../redux/slices/logicSlices'
import { themes } from '../../../utils/themes'
import styles from './reset-password-question.module.css'

const ResetPasswordQuestion = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm()
	const [resetPassword, { isLoading }] = useResetPasswordMutation()

	const onSubmit = useCallback(
		async data => {
			try {
				dispatch(setStorage(data))
				const res = await resetPassword(data).unwrap()
				if (res) {
					navigate('/confirm-code')
				}
			} catch (e) {
				toast.error(e?.data?.message || 'Ошибка')
			}
		},
		[dispatch, resetPassword, navigate]
	)

	return (
		<div style={themes.container} className={styles.block}>
			<Helmet>
				<title>Забыли пароль | TezJarnama.ru - Восстановление доступа</title>
				<meta
					name='description'
					content='Восстановите доступ к своему аккаунту на TezJarnama.ru, если вы забыли пароль. Следуйте инструкциям для создания нового пароля.'
				/>
				<meta
					name='keywords'
					content='забыли пароль, восстановить пароль, TezJarnama, восстановление аккаунта, объявления'
				/>
				<meta property='og:title' content='Забыли пароль | TezJarnama.ru' />
				<meta
					property='og:description'
					content='Восстановите доступ к своему аккаунту на TezJarnama.ru, если вы забыли пароль.'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/resetPassword' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='Забыли пароль | TezJarnama.ru' />
				<meta
					name='twitter:description'
					content='Следуйте инструкциям на странице, чтобы восстановить пароль и получить доступ к своему аккаунту на TezJarnama.ru.'
				/>
			</Helmet>
			<h1 className={styles.title}>Восстановление пароля</h1>
			<div className={styles.formBlock}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<label className={styles.label}>
						<p className={styles.labelTitle}>
							Почта<span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('email', {
								required: true,
								pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							})}
							aria-invalid={errors.email ? 'true' : 'false'}
							placeholder={'Введите email'}
						/>
						{errors.email?.type === 'required' && (
							<p className={styles.error}>Email обязателен</p>
						)}
						{errors.email?.type === 'pattern' && (
							<p className={styles.error}>Некорректный формат email</p>
						)}
					</label>

					<div className={styles.blockBtn}>
						<button
							type='submit'
							className={styles.submit}
							disabled={isLoading}
						>
							{isLoading ? (
								<CircularProgress color='inherit' size={30} />
							) : (
								'Отправить'
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default ResetPasswordQuestion
