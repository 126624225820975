import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	user: null,
	dataId: null,
	token: null,
}
const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload
		},
		logout: state => {
			state.user = null
			state.token = null
			state.dataId = null
		},
		setToken: (state, action) => {
			state.token = action.payload
		},
		dataRegister: (state, action) => {
			state.dataId = action.payload
		},
	},
})

export const { setUser, logout, dataRegister, setToken } = userSlice.actions
export default userSlice.reducer
