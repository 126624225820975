import Button from '@mui/material/Button'
import React from 'react'
import { IoIosClose } from 'react-icons/io'
import styles from './module-mobile.module.css'
const ModalMobile = ({
	open,
	setClose,
	setMetro,
	metro,
	search,
	setSearch,
	data,
}) => {
	const checked = (item, name, color) => {
		const isItemSelected = metro?.some(selectedItem => selectedItem.id === item)
		if (isItemSelected) {
			const filterDelete = metro?.filter(elem => elem.id !== item)
			setMetro(filterDelete)
		} else {
			setMetro([...metro, { id: item, name: name, color: color }])
		}
	}

	const endChecked = id => {
		const filter = metro?.filter(item => item.id !== id)
		setMetro(filter)
	}
	const saveMetro = () => {
		setClose(false)
	}
	const exitMetro = () => {
		setMetro([])
		setClose(false)
	}
	return (
		<div className={styles.mobile}>
			<div className={styles.wrapper}>
				<div className={styles.main}>
					<div className={styles.blockSearch}>
						<input
							className={styles.inputSearch}
							type='text'
							placeholder={'Поиск...'}
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
					</div>
					<div className={styles.blockValues}>
						{metro &&
							metro?.map(elem => (
								<div className={styles.mainValue}>
									<p className={styles.values} key={elem.index}>
										{elem.name}
									</p>
									<button
										type='button'
										onClick={() => endChecked(elem.id)}
										className={styles.btnClose}
									>
										<IoIosClose />
									</button>
								</div>
							))}
					</div>
					<div className={styles.station}>
						{data.map(element => {
							return (
								<div
									key={element._id}
									className={styles.metro}
									onClick={() =>
										checked(element._id, element.name, element.color)
									}
								>
									<input
										className={styles.checkbox}
										type='checkbox'
										checked={metro.some(
											selectedItem => selectedItem.id === element._id
										)}
									/>
									<span
										style={{ backgroundColor: `${element.color}` }}
										className={styles.color}
									></span>
									<p className={styles.name} key={element._id}>
										{element.name}
									</p>
								</div>
							)
						})}
					</div>
					<div className={styles.btnSave}>
						<Button variant={'contained'} onClick={saveMetro} autoFocus>
							Сохранить
						</Button>
						<Button
							variant={'contained'}
							color={'error'}
							autoFocus
							onClick={exitMetro}
						>
							Отмена
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalMobile
