import Skeleton from '@mui/material/Skeleton'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGetCategoryQuery } from '../../../redux/rtk-query/category-api'
import {
	getCategoryId,
	getMetroFilter,
	getParentCategoryId,
	getSecondCategoryId,
} from '../../../redux/slices/productSlices'
import { themes } from '../../../utils/themes'
import styles from './category.module.css'

// Компонент для отображения скелетонов
const SkeletonBlock = () => (
	<Skeleton animation='wave' variant='circular' className={styles.block2} />
)

// Компонент для отображения категории
const CategoryBlock = ({ category, onClick }) => (
	<div
		key={category._id}
		onClick={() =>
			onClick(category._id, category.category_en, category.category_ru)
		}
		className={styles.block}
	>
		<h2 className={styles.subTitle}>{category.category_ru}</h2>
		<img
			className={styles.img}
			src={category.images}
			alt={category.category_ru}
			loading='lazy'
		/>
	</div>
)

const Category = () => {
	const { data, isLoading, error } = useGetCategoryQuery()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleLink = (id, link, title) => {
		dispatch(getCategoryId({ id, title, link }))
		dispatch(getParentCategoryId(''))
		dispatch(getSecondCategoryId(''))
		dispatch(getMetroFilter([]))
		navigate(`post/${link}`)
	}

	if (error) {
		return (
			<div style={themes.container}>Произошла ошибка при загрузке данных.</div>
		)
	}

	// Разделяем данные на две части
	const categories = data?.data || []
	const firstPart = categories.slice(0, 7)
	const secondPart = categories.slice(7)

	return (
		<div>
			<div className={styles.blocks}>
				<h1 className={styles.title}>Недвижимость</h1>
				<div className={`${styles.main} ${styles.main2}`}>
					{isLoading
						? Array.from({ length: 7 }).map((_, index) => (
								<SkeletonBlock key={index} />
						  ))
						: firstPart.map(category => (
								<CategoryBlock
									key={category._id}
									category={category}
									onClick={handleLink}
								/>
						  ))}
				</div>
				<h1 className={styles.title}>Другое</h1>
				<div className={styles.main}>
					{isLoading
						? Array.from({ length: 7 }).map((_, index) => (
								<SkeletonBlock key={index + 7} />
						  ))
						: secondPart.map(category => (
								<CategoryBlock
									key={category._id}
									category={category}
									onClick={handleLink}
								/>
						  ))}
				</div>
			</div>
		</div>
	)
}

export default React.memo(Category)
