//1
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { HiXMark } from 'react-icons/hi2'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import camera from '../../../assets/images/image/camera.png'
import { useGetCategoryQuery } from '../../../redux/rtk-query/category-api'
import {
	useGetMetroQuery,
	useSearchMetroQuery,
} from '../../../redux/rtk-query/metro-api'
import { useAddPostMutation } from '../../../redux/rtk-query/post-api'
import { themes } from '../../../utils/themes'
import CategoryModal from '../category-modal/category-modal'
import ModalMetro from '../modal-metro'
import ModalMobile from '../modal-mobile'
import styles from './add-post.module.css'

const AddPost = () => {
	const description =
		'Разместите ваше объявление на TezJarnama быстро и легко. Подайте объявления о продаже товаров, недвижимости, автомобилей и других услуг. Наш удобный интерфейс и широкая аудитория помогут вам найти покупателей и продавцов.'
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const navigate = useNavigate()
	const { user } = useSelector(state => state.users)
	const { data: category } = useGetCategoryQuery('')
	const { data: station } = useGetMetroQuery('')
	const [searchValue, setSearchValue] = useState('')
	const [postAdd, { isLoading }] = useAddPostMutation()
	const [width, setWidth] = useState(window.innerWidth)
	const { data: metros,isLoading:loadingMetro } = useSearchMetroQuery(searchValue)
	const [imagePreviews, setImagePreviews] = useState([])
	const [metro, setMetro] = useState([])
	const [categoryId, setCategoryId] = useState([])
	const [open, setOpen] = useState(false)
	const [categoryModal, setCategoryModal] = useState(false)
	const [validImg, setValidImg] = useState('')
	const [validMetro, setValidMetro] = useState('')
	const [title, setTitle] = useState('')
	const [titleError, setTitleError] = useState('')
	const [validCategory, setValidCategory] = useState('')

	// Обработка изменения изображений
	const handleImageChange = e => {
		const files = Array.from(e.target.files)
		if (files.length + imagePreviews.length > 5) {
			toast.error('Вы можете загрузить не более 5 фотографий')
			return
		}
		const newPreviews = files.map(file => ({
			data: file,
			file: URL.createObjectURL(file),
		}))
		setImagePreviews(prev => [...prev, ...newPreviews])
	}

	const categoryObj = {
		categoryRef: categoryId[0]?.categoryRef || null,
		category_title: categoryId[0]?.text || null,
		parent: {
			parentRef: categoryId[1]?.parentRef || null,
			parent_title: categoryId[1]?.text || null,
			second: {
				secondRef: categoryId[2]?.secondRef || null,
				second_title: categoryId[2]?.text || null,
			},
		},
	}

	const deleteImg = index => {
		setImagePreviews(prev => prev.filter((_, ind) => ind !== index))
	}

	const deleteMetro = text => {
		setMetro(prev => prev.filter(item => item.name !== text))
	}

	const validateForm = () => {
		let hasError = false
		if (imagePreviews.length === 0) {
			setValidImg('Добавьте хотя бы 1 фотографию')
			hasError = true
		}
		if (!title) {
			setTitleError('Обязательное поле')
			hasError = true
		}
		if (categoryId.length === 0) {
			setValidCategory('Выберите категорию')
			hasError = true
		}
		if (metro.length === 0) {
			setValidMetro('Выберите станцию метро')
			hasError = true
		}
		return !hasError
	}

	const onSubmit = async data => {
		if (!validateForm()) return

		const formData = new FormData()
		formData.append('userId', user._id)
		formData.append('title', title)
		formData.append('description', data.description)
		formData.append('sum', data.price || 0)
		formData.append('address', data.address)
		formData.append('status', true)
		formData.append('category', JSON.stringify(categoryObj))
		imagePreviews.forEach(elem => formData.append('imagesUrl', elem.data))
		metro.forEach(elem => formData.append('metro', elem.id))

		try {
			await postAdd(formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			}).unwrap()
			toast.success('Добавлено')
			navigate('/')
		} catch (error) {
			toast.error(error.data.error)
		}
	}

	useEffect(() => {
		if (imagePreviews.length > 0) setValidImg('')
		if (categoryId.length > 0) setValidCategory('')
		if (metro.length > 0) setValidMetro('')
	}, [imagePreviews, categoryId, metro])

	useEffect(() => {
		const handleResize = event => setWidth(event.target.innerWidth)
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div className={styles.main} style={themes.container}>
			<Helmet>
				<title>Разместите объявление | TezJarnama</title>
				<meta name='description' content={description} />
				<meta
					property='og:title'
					content='Разместите объявление | TezJarnama'
				/>
				<meta property='og:description' content={description} />
				<meta property='og:image' content='/public/logo.png' />
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://tezjarnama.ru/addPost' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta
					name='twitter:title'
					content='Разместите объявление | TezJarnama'
				/>
				<meta name='twitter:description' content={description} />
				<meta property='og:site_name' content='TezJarnama' />
				<meta property='og:type' content='website' />
			</Helmet>
			<h1 className={styles.title}>Разместить БЕСПЛАТНОЕ объявление просто!</h1>
			<form
				className={styles.form}
				onSubmit={handleSubmit(onSubmit)}
				encType='multipart/form-data'
			>
				{/* Загрузка фото */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Загрузите фото <span>*</span>
					</h4>
					<label className={styles.imgLabel}>
						<div className={styles.labelDiv}>
							<img
								className={styles.imgCamera}
								src={camera}
								alt='camera'
								loading='lazy'
							/>
							<p className={styles.ten}>Не более 5</p>
						</div>
						<input
							type='file'
							multiple
							accept='image/*'
							onChange={handleImageChange}
							className={styles.inputImg}
						/>
					</label>
					{validImg && <p className={styles.error}>{validImg}</p>}
					<div className={styles.imgPrev}>
						{imagePreviews.map((preview, index) => (
							<div className={styles.imgPosition} key={index}>
								<button
									className={styles.delete}
									onClick={() => deleteImg(index)}
								>
									<HiXMark />
								</button>
								<img
									src={preview.file}
									alt='img add'
									className={styles.imgAdd}
									loading='lazy'
								/>
							</div>
						))}
					</div>
				</div>

				{/* Заголовок */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Заголовок <span>*</span>
					</h4>
					<input
						type='text'
						onChange={e => setTitle(e.target.value)}
						value={title}
						className={`${styles.heading} ${styles.titleInput}`}
						placeholder='iphone 14pro max, Сдам комнату'
						maxLength={40}
					/>
					<p className={styles.titleLimit}>{title.length}/40</p>
					{titleError && <p className={styles.error}>{titleError}</p>}
				</div>

				{/* Описание */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Описание <span>*</span>
					</h4>
					<textarea
						{...register('description', { required: true })}
						className={styles.textArea}
						placeholder='Продам Iphone 14 в идеальном состоянии. Причина продажи: покупка нового телефона'
					/>
					{errors.description?.type === 'required' && (
						<p className={styles.error} role='alert'>
							Обязательное поле
						</p>
					)}
				</div>

				{/* Категория */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Категория <span>*</span>
					</h4>
					<div className={styles.heading2}>
						<button
							className={styles.btnMetro}
							onClick={() => setCategoryModal(true)}
							type='button'
						>
							Выбрать
						</button>
						<div className={styles.categoryBlock}>
							{categoryId.map((elem, index) => (
								<div className={styles.categoryText} key={index}>
									<p className={styles.textCategory}>{elem.text}</p>
									<span>/</span>
								</div>
							))}
						</div>
					</div>
					{validCategory && <p className={styles.error}>{validCategory}</p>}
				</div>

				{/* Адрес */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Адрес<span>*</span>
					</h4>
					<input
						{...register('address', { required: true })}
						className={styles.heading}
						type='text'
						placeholder='Введите адрес'
					/>
					{errors.address?.type === 'required' && (
						<p className={styles.error} role='alert'>
							Обязательное поле
						</p>
					)}
				</div>

				{/* Метро */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Метро<span>*</span>
					</h4>
					<div className={styles.heading2}>
						<button
							className={styles.btnMetro}
							type='button'
							onClick={() => setOpen(true)}
						>
							Добавить
						</button>
						{metro.map((elem, index) => (
							<div className={styles.station} key={index}>
								<p>{elem.name}</p>
								<button
									className={styles.closeMetro}
									onClick={() => deleteMetro(elem.name)}
								>
									<HiXMark />
								</button>
							</div>
						))}
					</div>
					{validMetro && <p className={styles.error}>{validMetro}</p>}
				</div>

				{/* Цена */}
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>Цена</h4>
					<input
						className={styles.heading}
						type='number'
						min={0}
						{...register('price', { required: false })}
						placeholder='Договорная'
					/>
				</div>

				{/* Кнопка отправки */}
				<button className={styles.submit} type='submit'>
					{isLoading ? <CircularProgress color='inherit' /> : 'Опубликовать'}
				</button>
			</form>

			{/* Модальные окна */}
			{width < 959
				? open && (
						<ModalMobile
							open={open}
							metro={metro}
							search={searchValue}
							setSearch={setSearchValue}
							data={
								metros && metros.metros.length > 0 ? metros.metros : station
							}
							loading={loadingMetro}
							setMetro={setMetro}
							setClose={setOpen}
						/>
				  )
				: open && (
						<ModalMetro
							open={open}
							loading={loadingMetro}
							metro={metro}
							search={searchValue}
							setSearch={setSearchValue}
							data={
								metros && metros.metros.length > 0 ? metros.metros : station
							}
							setMetro={setMetro}
							setClose={setOpen}
						/>
				  )}

			{categoryModal && (
				<CategoryModal
					open={categoryModal}
					setOpen={setCategoryModal}
					category={category}
					categoryId={categoryId}
					setCategoryId={setCategoryId}
				/>
			)}
		</div>
	)
}

export default AddPost
