//1
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FooterMain from '../../components/footer/footer-main'
import Header from '../../components/home-components/header'
import NavMobile from '../../components/home-components/nav-mobile'
import { routes } from '../../utils/routes'
import styles from './main-page.module.css'
const MainPage = () => {
	return (
		<div className={styles.wrapper}>
			<div>
				<Header />
				<NavMobile />
			</div>
			<div className={styles.main}>
				<Routes>
					{routes.map((item, index) => (
						<Route key={index} path={item.path} element={item.element} />
					))}
				</Routes>
			</div>
			<div className={styles.footer}>
				<FooterMain />
			</div>
		</div>
	)
}

export default MainPage
