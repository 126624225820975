//1
import { skipToken } from '@reduxjs/toolkit/query/react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styles1 from '../../components/advertisement/advertisement.module.css'
import {
	useAddFavoritesMutation,
	useDeleteFavoritesMutation,
	useFavoritesQuery,
} from '../../redux/rtk-query/favorites-api'
import {
	useGetDetailPostQuery,
	useGetSimilarPostQuery,
} from '../../redux/rtk-query/post-api'
import { detailProduct } from '../../redux/slices/productSlices'
import { themes } from '../../utils/themes'
import LoadingPage from '../error-page/loadingPage'
import styles from './detail-page.module.css'
const DetailProduct = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { detail } = useSelector(state => state.product)
	const { data, isLoading, isSuccess } = useGetDetailPostQuery(detail)
	const [imgNumber, setImgNumber] = useState(0)
	const [active, setActive] = useState(false)
	const [addFavorites] = useAddFavoritesMutation()
	const [deleteFavorites] = useDeleteFavoritesMutation()
	const { user } = useSelector(state => state.users)
	const { data: favorites } = useFavoritesQuery(
		user?._id ? user?._id : skipToken
	)
	const { data: similarData } = useGetSimilarPostQuery(detail)
	const handleImageNavigation = direction => {
		if (direction === 'next') {
			setImgNumber(prev =>
				data?.results?.imagesUrl.length - 1 === prev ? 0 : prev + 1
			)
		} else {
			setImgNumber(prev => (prev === 0 ? prev : prev - 1))
		}
	}

	const handleFavoriteToggle = async (id, isFavorite) => {
		if (!user) {
			return navigate('/login')
		}
		const data = { userId: user._id, adId: id }
		try {
			if (isFavorite) {
				await deleteFavorites(data)
				toast.info('Удалено из избранного')
			} else {
				await addFavorites(data)
				toast.success('Добавлено в избранное')
			}
		} catch (e) {
			console.error(e)
		}
	}
	const onSimilarDetail = id => {
		dispatch(detailProduct(id))
		navigate(`/product/${id}`)
	}
	const isFavorite = favorites?.some(fav => fav?.adId === data?.results?.adsId)

	if (isLoading && isSuccess) {
		return <LoadingPage />
	}

	return (
		<div style={themes.container}>
			<div className={styles.container}>
				{data ? (
					<div className={styles.wrapper}>
						<Helmet>
							<title>{`${data?.results?.title || 'Товар'} | TezJarnama`}</title>
							<meta
								name='description'
								content={
									data?.results?.description ||
									'Подробности о товаре на TezJarnama'
								}
							/>
							<meta
								property='og:title'
								content={`${data?.results?.title || 'Товар'} | TezJarnama`}
							/>
							<meta
								property='og:description'
								content={
									data?.results?.description ||
									'Объявление о продаже товара на TezJarnama'
								}
							/>
							<meta
								property='og:image'
								content={data?.results?.imagesUrl?.[0] || '/default-image.jpg'}
							/>
							<meta property='og:type' content='article' />
							<meta
								property='og:url'
								content={`https://tezjarnama.ru/product/${
									data?.results?.adsId || 'default-id'
								}`}
							/>
							<meta name='twitter:card' content='summary_large_image' />
							<meta
								name='twitter:title'
								content={`${data?.results?.title || 'Товар'} | TezJarnama`}
							/>
							<meta
								name='twitter:description'
								content={
									data?.results?.description ||
									'Объявление о продаже товара на TezJarnama'
								}
							/>
							<meta
								name='twitter:image'
								content={data?.results?.imagesUrl?.[0] || '/default-image.jpg'}
							/>
							<meta
								property='og:price:amount'
								content={data?.results?.sum || '0'}
							/>
							<meta property='og:price:currency' content='RUB' />
							<meta
								property='article:published_time'
								content={
									data?.results?.createdAt
										? new Date(data.results.createdAt).toISOString()
										: new Date().toISOString()
								}
							/>
						</Helmet>
						<div className={styles.left}>
							<h1 className={styles.title}>{data?.results?.title}</h1>
							<div className={styles.blockImg}>
								<div className={styles.blockBig}>
									{data?.results?.imagesUrl?.length > 1 && (
										<>
											<button
												className={styles.btnLeft}
												onClick={() => handleImageNavigation('prev')}
											>
												<HiChevronLeft />
											</button>
											<button
												className={styles.btnRight}
												onClick={() => handleImageNavigation('next')}
											>
												<HiChevronRight />
											</button>
										</>
									)}
									<img
										className={styles.bigImg}
										src={data?.results?.imagesUrl[imgNumber]}
										alt={data?.results?.title}
										loading='lazy'
									/>
								</div>
								<div className={styles.blockSmall}>
									{data?.results?.imagesUrl?.map((elem, i) => (
										<img
											key={i}
											className={styles.smallImg}
											style={
												imgNumber === i ? { border: '2px solid #0468FF' } : {}
											}
											onClick={() => setImgNumber(i)}
											src={elem}
											alt={data?.results?.title}
											loading='lazy'
										/>
									))}
								</div>
							</div>
							<div className={styles.mobilePrice}>
								<h1 className={styles.subTitle}>Цена</h1>
								<p className={styles.price}>
									{data?.results?.sum === 0
										? 'Договорная'
										: data.results?.sum.toLocaleString('ru-RU')}
									₽
								</p>
							</div>
							<div className={styles.blockAddress}>
								<h1 className={styles.subTitle}>Адрес</h1>
								<p className={styles.text}>ул. {data?.results?.address}</p>
								<div className={styles.metro}>
									{data?.results?.metro.map((elem, i) => (
										<p key={i} className={styles.metroBlock}>
											<span
												className={styles.colorMetro}
												style={{ backgroundColor: elem.color }}
											></span>
											{elem?.name}
										</p>
									))}
								</div>
							</div>
							<div className={styles.blockAddress}>
								<h1 className={styles.subTitle}>Описание</h1>
								<p className={styles.description}>
									{data?.results?.description}
								</p>
							</div>
							<div>
								<p className={styles.date}>
									Объявление с{' '}
									{new Date(data?.results?.createdAt).toLocaleString('ru-RU', {
										day: 'numeric',
										month: 'long',
										hour: '2-digit',
										minute: '2-digit',
									})}
								</p>
								<p className={styles.date}>{data?.results?.views} просмотров</p>
							</div>
						</div>
						<div className={styles.right}>
							<h1 className={styles.sum}>
								{data?.results?.sum === 0
									? 'Договорная'
									: data?.results?.sum.toLocaleString('ru-RU')}{' '}
								₽
							</h1>
							<div className={styles.user}>
								<span className={styles.avatar}>
									{data?.results?.user?.name[0]}
								</span>
								<p className={styles.name}>{data?.results?.user?.name}</p>
							</div>
							<div className={styles.btns}>
								<button
									onClick={() => {
										setActive(true)
										if (active && data?.results?.user?.phone) {
											navigator.clipboard
												.writeText(data.results.user.phone)
												.then(() => {
													toast.success('Номер скопирован!')
												})
												.catch(err => {
													toast.error('Ошибка при копировании номера: ', err)
												})
										}
									}}
									className={styles.number}
								>
									{active ? data?.results?.user?.phone : 'Показать номер'}
								</button>
								<button
									onClick={() =>
										handleFavoriteToggle(data?.results?.adsId, isFavorite)
									}
									className={styles.favorites}
								>
									{isFavorite ? (
										<AiFillHeart className={styles.brnFavor} color='#0468FF' />
									) : (
										<AiOutlineHeart className={styles.brnFavor} color='#0468FF' />
									)}
									{isFavorite
										? 'Удалить из избранного'
										: 'Добавить в избранное'}
								</button>
							</div>
							{similarData?.length > 0 && (
								<div className={styles.blockSubPost}>
									<button className={styles.btnSubPost}>
										Похожие объявления
									</button>
									<div>
										<div className={styles1.flex}>
											{similarData?.map((item, index) => {
												const isFavorite =
													user && favorites?.some(fav => fav.adId === item._id)
												return (
													<div
														key={item._id || index}
														className={styles1.block}
													>
														<img
															onClick={() => onSimilarDetail(item._id)}
															className={styles1.img}
															src={item.imagesUrl[0]}
															alt={item.title}
															loading='lazy'
															sizes='300px'
															srcset='small.jpg 300px, medium.jpg 300px, large.jpg 300px'
														/>
														<div className={styles1.infoBlock}>
															<div
																className={styles1.info}
																onClick={() => onSimilarDetail(item._id)}
															>
																<p className={styles1.subTitle}>{item.title}</p>
																<p className={styles1.price}>
																	{item.sum === 0
																		? 'Договорная'
																		: item.sum.toLocaleString('ru-RU')}
																	{item.sum !== 0 && '₽'}
																</p>
																<p className={styles1.metro}>
																	<span
																		className={styles1.color}
																		style={{
																			backgroundColor: item.metro[0]?.color,
																		}}
																	/>
																	<span>{item.metro[0]?.name}</span>
																</p>
																<p className={styles1.createdAt}>
																	{new Date(item.createdAt).toLocaleString(
																		'ru-RU',
																		{
																			day: 'numeric',
																			month: 'long',
																			hour: '2-digit',
																			minute: '2-digit',
																		}
																	)}
																</p>
															</div>
															<button
																type='button'
																onClick={() =>
																	handleFavoriteToggle(item._id, isFavorite)
																}
																className={
																	isFavorite ? styles1.button2 : styles1.button
																}
															>
																{isFavorite ? (
																	<AiFillHeart />
																) : (
																	<AiOutlineHeart />
																)}
															</button>
														</div>
													</div>
												)
											})}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				) : (
					<>
						<LoadingPage />
					</>
				)}
			</div>
		</div>
	)
}

export default DetailProduct
