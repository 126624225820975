//1
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import LoadingPage from '../../../pages/error-page/loadingPage'
import { useMyPostQuery } from '../../../redux/rtk-query/post-api'
import {
	detailProduct,
	onDeletePost,
} from '../../../redux/slices/productSlices'
import { themes } from '../../../utils/themes'
import ModalDelete from './modal/ModalDelete'
import styles from './my-post.module.css'
const MyPost = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [open, setOpen] = React.useState(false)
	const user = useSelector(state => state.users.user)
	const { data: myPost, isLoading } = useMyPostQuery(user._id)

	const handleDetailsProduct = id => {
		dispatch(detailProduct(id))
		navigate(`/product/${id}`)
	}

	const handleChange = id => {
		dispatch(detailProduct(id))
		navigate(`/changePost/${id}`)
	}

	const handleDelete = id => {
		dispatch(onDeletePost(id))
		setOpen(true)
	}

	if (isLoading) {
		return <LoadingPage />
	}

	return (
		<div style={themes.container}>
			<h1 className={styles.title}>Мои объявления</h1>
			<div className={styles.flex}>
				{myPost && myPost.results.length > 0 ? (
					myPost.results.map(elem => (
						<div key={elem.adsId} className={styles.block}>
							<img
								onClick={() => handleDetailsProduct(elem.adsId)}
								className={styles.img}
								loading='lazy'
								src={elem?.imagesUrl[0] || 'default-image-url'} // Добавьте URL по умолчанию, если изображение отсутствует
								alt={elem.title || 'Изображение'}
							/>
							<div
								onClick={() => handleDetailsProduct(elem.adsId)}
								className={styles.infoBlock}
							>
								<div className={styles.info}>
									<p className={styles.subTitle}>{elem.title}</p>
									<p className={styles.price}>
										{elem.sum.toLocaleString('ru-RU')}₽
									</p>
									<p className={styles.metro}>
										{elem.metro[0] && (
											<>
												<span
													className={styles.color}
													style={{ backgroundColor: `${elem.metro[0].color}` }}
												></span>
												<span>{elem.metro[0].name}</span>
											</>
										)}
									</p>
									<p className={styles.createdAt}>
										{new Date(elem.createdAt).toLocaleString('ru-RU', {
											day: 'numeric',
											month: 'long',
											hour: '2-digit',
											minute: '2-digit',
										})}
									</p>
								</div>
							</div>
							<div className={styles.blockActive}>
								<button
									onClick={() => handleChange(elem.adsId)}
									className={styles.change}
								>
									Изменить
								</button>
								<button
									onClick={() => handleDelete(elem.adsId)}
									className={styles.delete}
								>
									Удалить
								</button>
							</div>
						</div>
					))
				) : (
					<div>Нет объявлений</div>
				)}
			</div>
			<ModalDelete setOpen={setOpen} open={open} />
		</div>
	)
}

export default MyPost
