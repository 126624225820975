import { api } from './setting'

export const metroApi = api.injectEndpoints({
	endpoints: build => ({
		getMetro: build.query({
			query: () => ({
				url: `/metro`,
				method: 'GET',
			}),
		}),
		searchMetro: build.query({
			query: data => ({
				url: '/metro/search',
				params: { search: data },
			}),
		}),
	}),
})
export const { useGetMetroQuery, useSearchMetroQuery } = metroApi
