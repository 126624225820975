//1
import CircularProgress from '@mui/material/CircularProgress'
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { setStorage } from '../../../redux/slices/logicSlices'
import {
	dataRegister,
	setToken,
	setUser,
} from '../../../redux/slices/userSlices'
import { themes } from '../../../utils/themes'
import styles from './profile-mobile.module.css'

const ProfileMobile = () => {
	const user = useSelector(state => state.users.user)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	// Мемоизация функции выхода
	const logoutUser = useCallback(() => {
		dispatch(setToken(null))
		dispatch(setUser(null))
		dispatch(dataRegister(null))
		dispatch(setStorage({}))
		Cookies.remove('token')
		navigate('/')
	}, [dispatch, navigate])

	if (!user) {
		return (
			<div>
				<div className={styles.circul}>
					<CircularProgress color={'info'} size={30} />
				</div>
			</div>
		)
	}

	return (
		<div className={styles.wrapper} style={themes.container}>
			<Helmet>
				<title>
					Профиль пользователя | TezJarnama.ru - Размещение объявлений
				</title>
				<meta
					name='description'
					content='Просмотрите профиль пользователя и его объявления на TezJarnama.ru. Найдите выгодные предложения по недвижимости, электронике, автомобилям и многому другому.'
				/>
				<meta
					name='keywords'
					content='профиль пользователя, TezJarnama, объявления, недвижимость, автомобили, электроника'
				/>
				<meta
					property='og:title'
					content='Профиль пользователя | TezJarnama.ru'
				/>
				<meta
					property='og:description'
					content='Посмотрите профиль пользователя на TezJarnama.ru и найдите интересные объявления.'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/profile' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta
					name='twitter:title'
					content='Профиль пользователя | TezJarnama.ru'
				/>
				<meta
					name='twitter:description'
					content='Просмотрите профиль пользователя и его объявления на TezJarnama.ru.'
				/>
			</Helmet>
			<div className={styles.main}>
				<h1 className={styles.title}>Профиль</h1>
				<div className={styles.block}>
					<span className={styles.img}>{user.fullName?.[0]}</span>
					<div className={styles.nameBlock}>
						<p className={styles.name}>{user.fullName}</p>
						<p className={styles.number}>{user.phoneNumber}</p>
						<p className={styles.number}>{user.email}</p>
					</div>
				</div>
				<div className={styles.block}>
					<Link to='/myPost' className={styles.btns}>
						Мои объявления
					</Link>
					<Link to='/favorites' className={styles.btns}>
						Избранное
					</Link>
					<Link to='/help' className={styles.btns}>
						Помощь
					</Link>
					<button onClick={logoutUser} className={styles.btns2}>
						Выйти
					</button>
				</div>
			</div>
		</div>
	)
}

export default ProfileMobile
