import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { categoryApi } from '../rtk-query/category-api'
import { favoritesApi } from '../rtk-query/favorites-api'
import { metroApi } from '../rtk-query/metro-api'
import { postApi } from '../rtk-query/post-api'
import { telegramHelpApi } from '../rtk-query/telegram-hepl-api'
import { userApi } from '../rtk-query/user-api'
import logicSlices from './logicSlices'
import productSlices from './productSlices'
import userSlices from './userSlices'

const productPersist = {
	key: 'product',
	storage,
	blacklist: ['metro', 'minPrice', 'maxPrice'],
}
export const rootReducer = combineReducers({
	users: userSlices,
	logic: logicSlices,
	product: persistReducer(productPersist, productSlices),
	[userApi.reducerPath]: userApi.reducer,
	[categoryApi.reducerPath]: categoryApi.reducer,
	[metroApi.reducerPath]: metroApi.reducer,
	[postApi.reducerPath]: postApi.reducer,
	[favoritesApi.reducerPath]: favoritesApi.reducer,
	[telegramHelpApi.reducerPath]: telegramHelpApi.reducer,
})
