import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import React from 'react'
import { IoIosClose } from 'react-icons/io'
import styles from './modal-metro.module.css'

const ModalMetro = ({
	open,
	setClose,
	setMetro,
	metro,
	search,
	setSearch,
	data,
	loading,
}) => {
	const handleItemToggle = (id, name, color) => {
		const isItemSelected = metro.some(selectedItem => selectedItem.id === id)
		if (isItemSelected) {
			setMetro(metro.filter(item => item.id !== id))
		} else {
			setMetro([...metro, { id, name, color }])
		}
	}

	const handleRemoveItem = id => {
		setMetro(metro.filter(item => item.id !== id))
	}

	const handleSave = () => {
		setClose(false)
	}

	const handleCancel = () => {
		setMetro([])
		setClose(false)
	}

	return (
		<Dialog
			className={styles.main}
			open={open}
			onClose={() => setClose(false)}
			aria-labelledby='responsive-dialog-title'
		>
			<DialogContent className={styles.block}>
				<h1 className={styles.title}>Выберите метро</h1>
				<div className={styles.blockSearch}>
					<input
						className={styles.inputSearch}
						type='text'
						placeholder='Поиск...'
						value={search}
						onChange={e => setSearch(e.target.value)}
					/>
				</div>
				<div>
					<div className={styles.blockValues}>
						{loading ? (
							<div>Загрузка</div>
						) : (
							metro &&
							metro.map(elem => (
								<div className={styles.mainValue} key={elem.id}>
									<p className={styles.values}>{elem.name}</p>
									<button
										type='button'
										onClick={() => handleRemoveItem(elem.id)}
										className={styles.btnClose}
									>
										<IoIosClose />
									</button>
								</div>
							))
						)}
					</div>
				</div>

				<div className={styles.station}>
					{data &&
						data.map(element => (
							<div
								key={element._id}
								className={styles.metro}
								onClick={() =>
									handleItemToggle(element._id, element.name, element.color)
								}
							>
								<input
									className={styles.checkbox}
									type='checkbox'
									checked={metro.some(
										selectedItem => selectedItem.id === element._id
									)}
									readOnly
								/>
								<span
									style={{ backgroundColor: element.color }}
									className={styles.color}
								></span>
								<p className={styles.name}>{element.name}</p>
							</div>
						))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={handleSave} autoFocus>
					Сохранить
				</Button>
				<Button
					variant='contained'
					color='error'
					autoFocus
					onClick={handleCancel}
				>
					Отмена
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ModalMetro
