//1
import CircularProgress from '@mui/material/CircularProgress'
import React, { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useResetPasswordCodeMutation } from '../../../redux/rtk-query/user-api'
import { themes } from '../../../utils/themes'
import styles from '../code-email/code-email.module.css'

const ResetPassword = () => {
	const {
		formState: { errors },
	} = useForm()
	const navigate = useNavigate()
	const { storage } = useSelector(state => state.logic)
	const [resetPasswordCode, { isLoading }] = useResetPasswordCodeMutation()
	const inputsRef = useRef([])
	const [code, setCode] = useState(['', '', '', ''])
	const confirmCode = code.join('')

	// Обработка изменения значения
	const handleChange = useCallback(
		(index, event) => {
			const value = event.target.value
			const newCode = [...code]
			newCode[index] = value
			setCode(newCode)

			if (value.length > 0 && index < 3) {
				inputsRef.current[index + 1].focus()
			}
		},
		[code]
	)

	// Обработка нажатия клавиш
	const handleKeyDown = useCallback(
		(index, event) => {
			if (event.key === 'Backspace' && !code[index] && index > 0) {
				inputsRef.current[index - 1].focus()
			}
		},
		[code]
	)

	// Обработка отправки формы
	const onSubmit = useCallback(async () => {
		const { email } = storage
		try {
			const res = await resetPasswordCode({
				email,
				code: confirmCode,
			}).unwrap()
			if (res) {
				navigate('/new-password')
			}
		} catch (e) {
			setCode(['', '', '', ''])
			toast.error(e?.data?.message)
		}
	}, [confirmCode, navigate, resetPasswordCode, storage])

	return (
		<div className={styles.wrapper} style={themes.container}>
			<h1 className={styles.title}>Подтверждение кода</h1>
			<div className={styles.mainCode}>
				<h4 className={styles.subTitle}>Введите код из почты</h4>
				<div className={styles.codeBlock}>
					{code.map((digit, index) => (
						<input
							style={{
								borderColor: confirmCode.length < 4 ? 'red' : '#0468FF',
							}}
							key={index}
							className={styles.code}
							type='number'
							value={digit}
							onChange={event => handleChange(index, event)}
							onKeyDown={event => handleKeyDown(index, event)}
							maxLength={1}
							ref={input => (inputsRef.current[index] = input)}
						/>
					))}
				</div>

				<div className={styles.blockBtn}>
					{isLoading ? (
						<button className={styles.btn}>
							<CircularProgress color='inherit' size={30} />
						</button>
					) : (
						<button
							disabled={confirmCode.length < 4}
							style={{
								backgroundColor: confirmCode.length < 4 ? '#d4e6fa' : '#0468FF',
								color: confirmCode.length < 4 ? '#0468FF' : '#fff',
								cursor: confirmCode.length < 4 ? 'default' : 'pointer',
							}}
							onClick={onSubmit}
							className={styles.btn}
						>
							Подтвердить
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
