//1
import CircularProgress from '@mui/material/CircularProgress'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useNewPasswordMutation } from '../../../../redux/rtk-query/user-api'
import { themes } from '../../../../utils/themes'
import styles from '../../login/login.module.css'

const NewPassword = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const navigate = useNavigate()
	const { storage } = useSelector(state => state.logic)
	const [toggle, setToggle] = useState(true)
	const [newPassword, { isLoading }] = useNewPasswordMutation()

	// Переключение видимости пароля
	const handleToggle = useCallback(() => {
		setToggle(prev => !prev)
	}, [])

	// Отправка данных формы
	const onSubmit = useCallback(
		async form => {
			const { email } = storage
			try {
				const res = await newPassword({
					email: email,
					password: form.password,
				}).unwrap()
				if (res) {
					navigate('/login')
				}
			} catch (e) {
				toast.error(e?.data?.message)
			}
		},
		[storage, navigate, newPassword]
	)

	return (
		<div className={styles.login} style={themes.container}>
			<h1 className={styles.title}>Изменить пароль</h1>
			<div className={styles.block}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<label className={`${styles.label} ${styles.label2}`}>
						<p className={styles.subTitle}>
							Новый пароль<span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('password', {
								required: 'Пароль обязателен',
								minLength: {
									value: 6,
									message: 'Пароль должен содержать минимум 6 символов',
								},
							})}
							aria-invalid={errors.password ? 'true' : 'false'}
							placeholder={'Введите пароль'}
							type={toggle ? 'password' : 'text'}
						/>
						<span onClick={handleToggle} className={styles.toggle}>
							{toggle ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
						</span>
						{errors.password && (
							<p className={styles.error} role='alert'>
								{errors.password.message}
							</p>
						)}
					</label>

					<div className={styles.blockBtn}>
						{isLoading ? (
							<button className={styles.submit}>
								<CircularProgress color='inherit' />
							</button>
						) : (
							<button className={styles.submit}>Подтвердить</button>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

export default NewPassword
