import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import img from '../../../assets/icon 3d/chat.png'
import { themes } from '../../../utils/themes'
import styles from './help.module.css'
const Help = () => {
	return (
		<div style={themes.container}>
			<Helmet>
				<title>
					Помощь | TezJarnama.ru - Часто задаваемые вопросы и поддержка
				</title>
				<meta
					name='description'
					content='Нужна помощь с TezJarnama.ru? Просмотрите наши часто задаваемые вопросы и инструкции, или свяжитесь с поддержкой для дополнительной помощи.'
				/>
				<meta
					name='keywords'
					content='помощь, поддержка, TezJarnama, FAQ, вопросы, инструкции, техническая поддержка, объявления'
				/>
				<meta property='og:title' content='Помощь | TezJarnama.ru' />
				<meta
					property='og:description'
					content='Нужна помощь с размещением объявлений или использованием TezJarnama.ru? Найдите ответы на часто задаваемые вопросы или свяжитесь с нами.'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/help' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='Помощь | TezJarnama.ru' />
				<meta
					name='twitter:description'
					content='Получите помощь по любым вопросам использования TezJarnama.ru. Ознакомьтесь с FAQ или свяжитесь с нашей службой поддержки.'
				/>
			</Helmet>
			<div className={styles.main}>
				<h1 className={styles.title}>Помощь</h1>
				<div className={styles.accardion}>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Как зарегистрироваться?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Регистрация на нашем сайте - это быстрый и простой процесс.
								Просто следуйте этим шагам:
								<br />
								<br />
								1. Перейти к Регистрации • На главной странице сайта нажмите
								кнопку «Войти». • В появившемся окне выберите опцию
								«Зарегистрироваться».
								<br />
								<br />
								2. Заполните Регистрационную Форму • Введите свое имя
								пользователя. Это имя, которое будет отображаться на сайте. •
								Укажите вашу электронную почту. Она будет использоваться для
								входа на сайт и для связи с вами. • Введите номер телефона для
								дополнительной безопасности вашего аккаунта. • Задайте надежный
								пароль. Старайтесь использовать уникальную комбинацию букв, цифр
								и специальных символов.
								<br />
								<br />
								3. Завершение Регистрации • После ввода всех данных нажмите
								кнопку «Зарегистрироваться». • На указанную электронную почту
								придет код подтверждения. • Введите этот код в соответствующее
								поле на сайте.
								<br />
								<br />
								4. Подтверждение Успешной Регистрации • После ввода кода ваш
								аккаунт будет активирован. • Теперь вы можете войти в свой
								аккаунт и начать пользоваться нашим сайтом!
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Какие преимущества личного кабинета и авторизации?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								С помощью личного кабинета авторизованный пользователь может:
								<br />
								<ol>
									<li>просматривать свои объявления;</li>
									<li>редактировать объявления</li>
									<li>бесплатно поднимать объявления вверх</li>
									<li>удалять объявления</li>
								</ol>
								Если для авторизации на сайте и в приложениях используется один
								и тот же логин, происходит синхронизация:
								<br />
								<ol>
									<li>избранных объявлений</li>
									<li>добавленных объявлений.</li>
								</ol>
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Я забыл пароль. Что делать?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Если вы забыли пароль от своего аккаунта, не беспокойтесь, его
								можно легко восстановить. Просто следуйте этим шагам:
								<br />
								<br />
								1. Начало Восстановления
								<br />
								• Перейдите на страницу входа в аккаунт и нажмите кнопку
								«Войти».
								<br />
								• В окне входа найдите и нажмите на ссылку или кнопку «Забыл
								пароль».
								<br />
								<br />
								2. Ввод Зарегистрированной Электронной Почты
								<br />
								• В появившемся окне введите адрес электронной почты, который вы
								использовали при регистрации аккаунта.
								<br />
								<br />
								3. Получение Кода Подтверждения
								<br />
								• Проверьте вашу электронную почту. Вам должен прийти код
								подтверждения.
								<br />
								• Введите полученный код в соответствующее поле на сайте.
								<br />
								<br />
								4. Создание Нового Пароля
								<br />
								• Если введенный код подтверждения верный, вы сможете установить
								новый пароль для своего аккаунта.
								<br />
								<br />
								5. Завершение Процесса
								<br />• После установки нового пароля вы сможете войти в свой
								аккаунт с использованием новых данных.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Я регистрировался по email, могу ли я зайти в Личный кабинет по
								номеру телефона?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								На данный момент вход в личный кабинет через номер телефона
								недоступен. Если вы зарегистрировались на нашем сайте используя
								электронную почту,
								<br />
								<br />• Будущие Обновления: Мы работаем над тем, чтобы добавить
								возможность регистрации и входа через номер телефона. Это
								обновление появится в ближайшем будущем, и мы уведомим вас, как
								только оно будет доступно.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Как изменить пароль?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Вы можете изменить свой пароль двумя способами:
								<br />
								1. Используя Функцию «Забыл Пароль»
								<br />
								• Нажмите на кнопку «Войти» на главной странице.
								<br />
								• Выберите опцию «Забыл пароль».
								<br />
								• Следуйте инструкциям для восстановления пароля. Вам будет
								отправлен код на электронную почту, который позволит создать
								новый пароль.
								<br />
								<br />
								2. Изменение Пароля в Личном Кабинете
								<br />
								• Войдите в свой личный кабинет.
								<br />
								• Выберите опцию для смены пароля и следуйте указаниям для
								создания нового.
								<br />
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Как добавить объявление и есть ли правила?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Мы внимательно следим за качеством опубликованных у нас на
								ресурсе объявлений и осуществляем их регулярную проверку.
								Следуйте нашим правилам и рекомендациям, необходимым для
								сохранения в нашей базе:
								<br />
								1) Вся указанная в объявлении информация должна соответствовать
								действительности. Имя и контактные данные должны принадлежать
								вам — не размещайте объявления за других людей.
								<br />
								<br />
								2) В объявлении должен быть указан действующий номер телефона,
								доступный для входящих звонков.
								<br />
								<br />
								3) Одно и то же объявление не должно размещаться несколько раз.
								<br />
								<br />
								4) Фотографии в объявлении должны соответствовать продаваемой
								недвижимости.
								<br />
								<br />
								5) Содержание объявления должно соответствовать рубрике, в
								которую оно подаётся. Модераторы имеют право удалить объявление,
								если оно не соответствует правилам публикации объявлений в
								подаваемую рубрику.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<Typography sx={{ fontWeight: 800 }}>
								Почему я не могу добавить фото к объявлению?
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Возможно, у вас недостаточное качество интернета. Попробуйте,
								пожалуйста, воспользоваться другим интернет каналом. Также, если
								вы отправите нам фото на email{' '}
								<a className={styles.email}>shuma@gmail.com</a>, мы добавим его
								к Вашему объявлению.
							</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={styles.chat}>
					<img
						className={styles.img}
						src={img}
						alt='tezjarnama help'
						loading='lazy'
					/>
					<div className={styles.chatImg}>
						<h3 className={styles.subTitle}>Вы не нашли решение проблемы?</h3>
						<p className={styles.text}>Задайте вопрос через форму</p>
						<Link to='/feedback' className={styles.link}>
							Обратиться в поддержку
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Help
