import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'
import Advertisement from '../../components/advertisement/advertisement'
import Category from '../../components/home-components/category'
import MainSearch from '../../components/home-components/main-search'
import { useGetPostsQuery } from '../../redux/rtk-query/post-api'
import { themes } from '../../utils/themes'
const HomePage = () => {
	const location = useLocation()
	const { refetch } = useGetPostsQuery()
	useEffect(() => {
		refetch()
	}, [location.key])
	return (
		<div style={themes.container}>
			<Helmet>
				<title>TezJarnama - Объявления для каждого</title>
				<meta
					name='description'
					content='Разместите объявления о продаже товаров, недвижимости, автомобилей и других услуг на TezJarnama. Легко и быстро найдите нужное!'
				/>
				<meta
					property='og:title'
					content='TezJarnama - Объявления для каждого'
				/>
				<meta
					property='og:description'
					content='Разместите объявления о продаже товаров, недвижимости, автомобилей и других услуг на TezJarnama.'
				/>
				<meta property='og:image' content='/public/logo.png' />
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://tezjarnama.ru' />
			</Helmet>
			<MainSearch />
			<Category />
			<Advertisement />
		</div>
	)
}

export default HomePage
