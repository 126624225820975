import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React, { useEffect, useState } from 'react'
import {
	useGetSecondCategoryQuery,
	useGetSubcategoryQuery,
} from '../../../redux/rtk-query/category-api'
import styles from './category-modal.module.css'

const CategoryModal = ({
	open,
	setOpen,
	category,
	setCategoryId,
	categoryId,
}) => {
	const [tabs, setTabs] = useState(1)
	const [idSub, setIdSub] = useState('')
	const [idSecond, setIdSecond] = useState('')
	const { data: subCategory, isLoading: isLoadingSub } =
		useGetSubcategoryQuery(idSub)
	const { data: secondCategory, isLoading: isLoadingSec } =
		useGetSecondCategoryQuery(idSecond)

	const addId = (id, text) => {
		setCategoryId([{ categoryRef: id, text }])
		setIdSub(id)
	}

	const addSub = (id, text) => {
		setCategoryId([...categoryId, { parentRef: id, text }])
		setIdSecond(id)
	}

	const addParent = (id, text) => {
		setCategoryId([...categoryId, { secondRef: id, text }])
		setIdSecond(id)
		setOpen(false)
	}

	useEffect(() => {
		if (idSub && !isLoadingSub) {
			subCategory ? setTabs(2) : setOpen(false)
		}
		if (idSecond && !isLoadingSec) {
			secondCategory ? setTabs(3) : setOpen(false)
		}
	}, [subCategory, secondCategory, idSub, idSecond, isLoadingSub, isLoadingSec])

	const renderCategoryContent = () => {
		if (isLoadingSub || isLoadingSec) {
			return (
				<div className={styles.loading}>
					<CircularProgress size={50} />
				</div>
			)
		}

		switch (tabs) {
			case 1:
				return (
					<div className={styles.category}>
						{category &&
							category.data.map(elem => (
								<div
									key={elem._id}
									onClick={() => addId(elem._id, elem.category_ru)}
									className={styles.categoryBlock}
								>
									<img
										className={styles.imgCategory}
										src={elem.images}
										alt={elem.category_en}
										loading="lazy"
									/>
									<p className={styles.textCategory}>{elem.category_ru}</p>
								</div>
							))}
					</div>
				)

			case 2:
				return (
					<div className={styles.category}>
						{subCategory &&
							subCategory.map(elem => (
								<div
									key={elem._id}
									onClick={() => addSub(elem._id, elem.parentTitle_ru)}
									className={styles.categoryBlock}
								>
									<p className={styles.textCategory}>{elem.parentTitle_ru}</p>
								</div>
							))}
					</div>
				)

			case 3:
				return (
					<div className={styles.category}>
						{secondCategory &&
							secondCategory.map(elem => (
								<div
									key={elem._id}
									onClick={() => addParent(elem._id, elem.secondTitle_ru)}
									className={styles.categoryBlock}
								>
									<p className={styles.textCategory}>{elem.secondTitle_ru}</p>
								</div>
							))}
					</div>
				)

			default:
				return null
		}
	}

	return (
		<Dialog
			className={styles.main}
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='responsive-dialog-title'
		>
			<DialogContent className={styles.block}>
				<div className={styles.nav}>
					<h1 className={styles.title}>
						Выберите {tabs === 1 ? 'категорию' : tabs > 1 ? 'подкатегорию' : ''}
					</h1>
				</div>

				{renderCategoryContent()}
			</DialogContent>
		</Dialog>
	)
}

export default CategoryModal
