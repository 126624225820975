//1
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import { skipToken } from '@reduxjs/toolkit/query/react'
import React, { useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import NoPost from '../../../pages/error-page/noPost/noPost'
import {
	useAddFavoritesMutation,
	useDeleteFavoritesMutation,
	useFavoritesQuery,
} from '../../../redux/rtk-query/favorites-api'
import { useGetPostCategoryQuery } from '../../../redux/rtk-query/post-api'
import {
	detailProduct,
	getMetroFilter,
} from '../../../redux/slices/productSlices'
import styles from './post-list.module.css'
const PostList = () => {
	const {
		categoryId,
		parentCategoryId,
		secondCategory,
		metro,
		minPrice,
		maxPrice,
		sortOption,
	} = useSelector(state => state.product)

	const [addFavorites] = useAddFavoritesMutation()
	const [deleteFavorites] = useDeleteFavoritesMutation()
	const { user } = useSelector(state => state.users)
	const { data: favorites } = useFavoritesQuery(user?._id ? user?._id : skipToken)

	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [queryParams, setQueryParams] = useState({
		page: 1,
		categoryRef: categoryId.id,
		parentRef: parentCategoryId ? parentCategoryId.id : undefined,
		secondRef: secondCategory ? secondCategory.id : undefined,
		minPrice: minPrice || undefined,
		maxPrice: maxPrice || undefined,
		metro: metro.length > 0 ? metro.map(el => el.name) : undefined,
		sortBy: sortOption,
	})

	const { data, isLoading, isFetching } = useGetPostCategoryQuery(queryParams)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	useEffect(() => {
		const updateQueryParams = () => {
			setQueryParams({
				page: 1,
				categoryRef: categoryId.id,
				parentRef: parentCategoryId ? parentCategoryId.id : undefined,
				secondRef: secondCategory ? secondCategory.id : undefined,
				minPrice: minPrice || undefined,
				maxPrice: maxPrice || undefined,
				metro: metro.length > 0 ? metro.map(el => el.name) : undefined,
				sortBy: sortOption,
			})
			setList([])
			setHasMore(true)
		}
		updateQueryParams()
	}, [
		categoryId,
		parentCategoryId,
		secondCategory,
		minPrice,
		maxPrice,
		metro,
		sortOption,
	])

	const fetchMoreData = () => {
		if (data?.results && !isFetching && !isLoading) {
			setList(prevList => [...prevList, ...data.results])
			if (data.totalPages > queryParams.page) {
				setQueryParams(prev => ({ ...prev, page: queryParams.page + 1 }))
			} else {
				setHasMore(false)
			}
		}
	}

	useEffect(() => {
		fetchMoreData()
	}, [])
	const handleFavorites = async (id, action) => {
		if (user) {
			const data = { userId: user._id, adId: id }
			try {
				if (action === 'add') {
					toast.info('Добавлено')
					await addFavorites(data)
				} else {
					toast.info('Удалено')
					await deleteFavorites(data)
				}
			} catch (e) {
				console.error(e)
			}
		} else {
			navigate('/login')
		}
	}

	const handleDetailsProduct = id => {
		dispatch(getMetroFilter([]))
		dispatch(detailProduct(id))
		navigate(`/product/${id}`)
	}

	if (data?.count === 0) {
		return <NoPost />
	}
	return (
		<div className={styles.main}>
			<div className={styles.advertisment}>
				<h1 className={styles.title}>Рекомендации для вас</h1>
				<div>
					{isLoading ? (
						Array.from({ length: 5 }, (_, index) => (
							<Skeleton
								key={index}
								animation='wave'
								variant='circular'
								className={styles.block2}
							/>
						))
					) : (
						<InfiniteScroll
							pageStart={0}
							loadMore={fetchMoreData}
							hasMore={hasMore}
							threshold={300}
							initialLoad={true}
							loader={
								<div key={0} className={styles.circul}>
									<CircularProgress color='info' size={30} />
								</div>
							}
						>
							<div className={styles.flex}>
								{list.map((elem, index) => (
									<div key={elem._id || index} className={styles.block}>
										<img
											onClick={() => handleDetailsProduct(elem._id)}
											className={styles.img}
											src={elem.imagesUrl[0]}
											alt={elem.title}
											loading='lazy'
										/>
										<div className={styles.infoBlock}>
											<div
												onClick={() => handleDetailsProduct(elem._id)}
												className={styles.info}
											>
												<p className={styles.subTitle}>{elem.title}</p>
												<p className={styles.price}>
													{elem.sum.toLocaleString('ru-RU')}₽
												</p>
												<p className={styles.metro}>
													<span
														className={styles.color}
														style={{ backgroundColor: elem.metro[0].color }}
													/>
													<span>{elem.metro[0].name}</span>
												</p>
												<p className={styles.createdAt}>
													{new Date(elem.createdAt).toLocaleString('ru-RU', {
														day: 'numeric',
														month: 'long',
														hour: '2-digit',
														minute: '2-digit',
													})}
												</p>
											</div>
											<button
												type='button'
												onClick={() =>
													handleFavorites(
														elem._id,
														favorites?.some(fav => fav?.adId === elem._id)
															? 'delete'
															: 'add'
													)
												}
												className={
													favorites?.some(fav => fav?.adId === elem._id)
														? styles.button2
														: styles.button
												}
											>
												{favorites?.some(fav => fav?.adId === elem._id) ? (
													<AiFillHeart />
												) : (
													<AiOutlineHeart />
												)}
											</button>
										</div>
									</div>
								))}
							</div>
						</InfiniteScroll>
					)}
				</div>
			</div>
		</div>
	)
}

export default PostList
