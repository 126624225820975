import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Error404 from '../../../pages/error-page/error-404'
import LoadingPage from '../../../pages/error-page/loadingPage'
import { useGetCategoryQuery } from '../../../redux/rtk-query/category-api'
import {
	useGetMetroQuery,
	useSearchMetroQuery,
} from '../../../redux/rtk-query/metro-api'
import {
	useChangePostMutation,
	useGetDetailPostQuery,
} from '../../../redux/rtk-query/post-api'
import { themes } from '../../../utils/themes'
import CategoryModal from '../category-modal/category-modal'
import ModalMetro from '../modal-metro/'
import ModalMobile from '../modal-mobile'
import styles from './change-post.module.css'

const ChangePost = () => {
	const { detail } = useSelector(state => state.product)
	const { data, isLoading } = useGetDetailPostQuery(detail)
	const { data: station } = useGetMetroQuery('')
	const [searchValue, setSearchValue] = useState('')
	const { data: metros } = useSearchMetroQuery(searchValue)
	const navigate = useNavigate()
	const { data: category } = useGetCategoryQuery('')
	const [width, setWidth] = useState(window.innerWidth)
	const [loading] = useState(false)
	const [changePost] = useChangePostMutation()

	const { register, handleSubmit, setValue } = useForm()
	const [metro, setMetro] = useState([])
	useEffect(() => {
		setValue('title', data?.results?.title)
		setValue('description', data?.results?.description)
		setValue('metro', data?.results?.metro)
		setValue('address', data?.results?.address)
		setValue('price', data?.results?.sum)
		setMetro(data?.results?.metro)
	}, [data, setValue, detail, setMetro])

	const [categoryId, setCategoryId] = useState(data?.results?.category)
	const [open, setOpen] = useState(false)

	const [categoryModal, setCategoryModal] = useState(false)

	const deleteMetro = text => {
		const filter = metro.filter(item => item.name !== text)
		setMetro(filter)
	}

	const onSubmit = async data => {
		const formData = new FormData()
		formData.append('id', detail)
		formData.append('title', data.title)
		formData.append('description', data.description)
		formData.append('sum', data.price)
		formData.append('address', data.address)
		formData.append('status', true)
		metro.forEach(elem => {
			formData.append(`metro`, elem.id || elem.metroId)
		})
		try {
			await changePost(
				{ id: detail, data: formData },
				{
					headers: { 'Content-Type': 'multipart/form-data' },
				}
			)
			navigate('/')
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		const handleResize = event => {
			setWidth(event.target.innerWidth)
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	if (loading) {
		return (
			<div style={themes.container}>
				<Error404 />
			</div>
		)
	}
	if (isLoading) {
		return <LoadingPage />
	}
	return (
		<div className={styles.main} style={themes.container}>
			<h1 className={styles.title}>Разместить БЕСПЛАТНОЕ объявление просто!</h1>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				encType='multipart/form-data'
			>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Фотографии <span></span>
					</h4>
					<div className={styles.imgPrev}>
						{data &&
							data?.results?.imagesUrl?.map((preview, index) => (
								<div key={index} className={styles.imgPosition}>
									<img
										src={preview.file ? preview.file : preview}
										alt={'Добавить фото'}
										className={styles.imgAdd}
										loading='lazy'
									/>
								</div>
							))}
					</div>
				</div>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Заголовок <span>*</span>
					</h4>
					<input
						className={styles.heading}
						type='text'
						value={setValue.title}
						{...register('title')}
					/>
				</div>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Описание <span>*</span>
					</h4>
					<textarea
						value={setValue.description}
						{...register('description')}
						className={styles.textArea}
					/>
				</div>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Категория <span>*</span>
					</h4>
					<div className={styles.heading2}>
						<div className={styles.categoryBlock}>
							<div className={styles.categoryText}>
								<p>{data.results?.category.category_title} </p>
								<span>/</span>
								<p>
									{data.results?.category?.parent?.parent_title &&
										data.results?.category?.parent?.parent_title}{' '}
								</p>
								<span>/</span>
								<p>
									{data.results?.category?.parent?.second?.second_title &&
										data.results?.category?.parent?.second.second_title}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Адрес<span>*</span>
					</h4>
					<input
						className={styles.heading}
						value={setValue.address}
						{...register('address')}
						type='text'
					/>
				</div>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>
						Метро<span>*</span>
					</h4>
					<div className={styles.heading2}>
						<button
							className={styles.btnMetro}
							type={'button'}
							onClick={() => setOpen(true)}
						>
							Добавить
						</button>
						{metro &&
							metro.map((elem, index) => (
								<div className={styles.station} key={index}>
									<p>{elem.name}</p>
									<button
										type={'button'}
										className={styles.closeMetro}
										onClick={() => deleteMetro(elem.name)}
									>
										<AiOutlineClose />
									</button>
								</div>
							))}
					</div>
				</div>
				<div className={styles.blockImg}>
					<h4 className={styles.subTitle}>Цена</h4>
					<input
						className={styles.heading}
						type='number'
						value={setValue.price}
						{...register('price')}
					/>
				</div>
				<div className={styles.btnsAdd}>
					<button className={styles.submit} type='submit'>
						Сохранить
					</button>
					<button className={styles.closeBtn}>Отмена</button>
				</div>
			</form>
			{width < 959
				? open && (
						<ModalMobile
							open={open}
							metro={metro}
							search={searchValue}
							setSearch={setSearchValue}
							data={
								metros && metros.metros.length > 0 ? metros.metros : station
							}
							setMetro={setMetro}
							setClose={setOpen}
						/>
				  )
				: open && (
						<ModalMetro
							open={open}
							metro={metro}
							search={searchValue}
							setSearch={setSearchValue}
							data={
								metros && metros.metros.length > 0 ? metros.metros : station
							}
							setMetro={setMetro}
							setClose={setOpen}
						/>
				  )}
			{categoryModal && (
				<CategoryModal
					open={categoryModal}
					setOpen={setCategoryModal}
					category={category}
					categoryId={categoryId}
					setCategoryId={setCategoryId}
				/>
			)}
		</div>
	)
}

export default ChangePost
