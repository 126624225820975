//1
import CircularProgress from '@mui/material/CircularProgress'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	useGetMeQuery,
	useLoginMutation,
} from '../../../redux/rtk-query/user-api'
import { setUser } from '../../../redux/slices/userSlices'
import { themes } from '../../../utils/themes'
import styles from './login.module.css'

const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { refetch: getMeRefetch, data } = useGetMeQuery()
	const [loginError, setLoginError] = useState(false)
	const [isLogin, setIsLogin] = useState(false)
	const [toggle, setToggle] = useState(true)
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm()
	const [login, { isLoading }] = useLoginMutation()

	// Обработчик отправки формы
	const onSubmit = useCallback(
		async form => {
			const { email, password } = form
			try {
				const res = await login({ email, password })
				if (res && res.data.token) {
					Cookies.set('token', res.data.token)
					await getMeRefetch()
					setLoginError(false)
					setIsLogin(true)
				}
			} catch (e) {
				toast.error('Неверный логин или пароль')
				setLoginError(true)
			}
		},
		[login, getMeRefetch]
	)

	useEffect(() => {
		if (isLogin && data) {
			dispatch(setUser(data))
			navigate('/')
			setIsLogin(false)
		}
	}, [isLogin, data, dispatch, navigate])

	// Переключатель видимости пароля
	const togglePasswordVisibility = useCallback(() => {
		setToggle(prevState => !prevState)
	}, [])

	return (
		<div style={themes.container} className={styles.login}>
			<Helmet>
				<title>Вход | TezJarnama.ru - Размещение объявлений</title>
				<meta
					name='description'
					content='Войдите на TezJarnama.ru, чтобы управлять своими объявлениями, просматривать отклики и находить выгодные предложения.'
				/>
				<meta
					name='keywords'
					content='вход, TezJarnama, объявления, личный кабинет, недвижимость, автомобили, электроника'
				/>
				<meta property='og:title' content='Вход | TezJarnama.ru' />
				<meta
					property='og:description'
					content='Войдите в свой аккаунт на TezJarnama.ru, чтобы управлять объявлениями и откликами.'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/login' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='Вход | TezJarnama.ru' />
				<meta
					name='twitter:description'
					content='Авторизуйтесь на TezJarnama.ru, чтобы управлять своими объявлениями и просматривать новые предложения.'
				/>
			</Helmet>
			<h1 className={styles.title}>Войти</h1>
			<div className={styles.block}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<label className={styles.label}>
						<p className={styles.subTitle}>
							Почта<span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('email', {
								required: 'Email обязателен',
								pattern: {
									value:
										/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1-3}\.[0-9]{1-3}\.[0-9]{1-3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Email в формате: name@email.com',
								},
							})}
							aria-invalid={errors.email ? 'true' : 'false'}
							placeholder={'Введите email'}
							style={{ borderColor: loginError ? 'red' : '#D9EDFF' }}
						/>
						{errors.email && (
							<p className={styles.error}>{errors.email.message}</p>
						)}
					</label>

					<label className={`${styles.label} ${styles.label2}`}>
						<p className={styles.subTitle}>
							Пароль<span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('password', {
								required: 'Пароль обязателен',
							})}
							aria-invalid={errors.password ? 'true' : 'false'}
							placeholder={'Введите пароль'}
							type={toggle ? 'password' : 'text'}
							style={{ borderColor: loginError ? 'red' : '#D9EDFF' }}
						/>
						<span onClick={togglePasswordVisibility} className={styles.toggle}>
							{toggle ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
						</span>
						{errors.password && (
							<p className={styles.error} role='alert'>
								{errors.password.message}
							</p>
						)}
					</label>

					<Link className={styles.quseition} to={'/resetPassword'}>
						Забыли пароль?
					</Link>
					<p className={styles.quseition}>
						Еще нет аккаунта,{' '}
						<Link className={styles.linkRegister} to={'/register'}>
							Зарегистрируйтесь
						</Link>
					</p>

					<div className={styles.blockBtn}>
						{isLoading ? (
							<button className={styles.submit} disabled>
								<CircularProgress color='inherit' />
							</button>
						) : (
							<button className={styles.submit} type='submit'>
								Войти
							</button>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

export default Login
