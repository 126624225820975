import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useDeletePostMutation } from '../../../../redux/rtk-query/post-api'
const ModalDelete = ({ open, setOpen }) => {
	const [deletePost] = useDeletePostMutation()
	const { deletePostId } = useSelector(state => state.product)

	const onDelete = async () => {
		try {
			await deletePost(deletePostId)
			setOpen(false)
			toast.show('Товар удален!')
		} catch (e) {
			console.log(e)
		}
	}
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					{'Удалить объявление?'}
				</DialogTitle>

				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Ваше объявление будет безвозвратно удалено
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDelete} color='error' variant='contained'>
						Удалить
					</Button>
					<Button variant='contained' onClick={handleClose} autoFocus>
						Отмена
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}

export default ModalDelete
