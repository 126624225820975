//1
import Skeleton from '@mui/material/Skeleton'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import LoadingPage from '../../pages/error-page/loadingPage'
import { useGetSecondCategoryQuery } from '../../redux/rtk-query/category-api'
import {
	getMetroFilter,
	getSecondCategoryId,
} from '../../redux/slices/productSlices'
import styles from './post-second-category.module.css'

const PostSecond = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { parentCategoryId, categoryId, parentLink } = useSelector(
		state => state.product
	)
	const {
		data: secondCategoryId,
		isLoading,
		isFetching,
		isError,
	} = useGetSecondCategoryQuery(parentCategoryId.id)
	const [active, setActive] = useState('')
	const start = 7

	const toLink = (id, link, title) => {
		dispatch(getSecondCategoryId({ id: id, title: title, link: link }))
		dispatch(getMetroFilter([]))
		setActive(title)
		navigate(`/${categoryId.link}/${parentLink}/${link}`)
	}

	if (isFetching) {
		return <LoadingPage />
	}

	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>{`${parentCategoryId?.title} | TezJarnama`}</title>
				<meta
					name='description'
					content={`Просмотрите товары в категории ${parentCategoryId?.title} на TezJarnama.`}
				/>
				<meta
					property='og:title'
					content={`${parentCategoryId?.title} | TezJarnama`}
				/>
				<meta
					property='og:description'
					content={`Просмотрите товары в категории ${parentCategoryId?.title} на TezJarnama.`}
				/>
				<meta property='og:type' content='website' />
				<meta
					property='og:url'
					content={`https://tezjarnama.ru/${parentCategoryId?.link}`}
				/>
				<meta property='og:type' content='website' />
				<meta property='og:site_name' content='TezJarnama' />
			</Helmet>
			<h1 className={styles.title}>{parentCategoryId.title}</h1>
			<h3 className={styles.link}>
				<Link to='/'>Главная </Link>/
				<Link to='/post/:name'>{categoryId.title}</Link>/
				<Link>{parentCategoryId.title}</Link>
			</h3>
			<div className={styles.main}>
				{isLoading
					? Array.from({ length: start }, (_, index) => (
							<Skeleton
								key={index}
								animation='wave'
								variant='circular'
								className={styles.block2}
							/>
					  ))
					: secondCategoryId &&
					  secondCategoryId.map(elem => (
							<div
								key={elem._id}
								style={
									active === elem.secondTitle_ru
										? {
												backgroundColor: '#0468FF',
												color: 'white',
												borderColor: '#0468FF',
										  }
										: null
								}
								onClick={() =>
									toLink(elem._id, elem.secondTitle_en, elem.secondTitle_ru)
								}
								className={styles.block}
							>
								<h1 className={styles.subTitle}>{elem.secondTitle_ru}</h1>
							</div>
					  ))}
			</div>
		</div>
	)
}

export default PostSecond
