import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import { skipToken } from '@reduxjs/toolkit/query/react'
import React, { useCallback, useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	useAddFavoritesMutation,
	useDeleteFavoritesMutation,
	useFavoritesQuery,
} from '../../redux/rtk-query/favorites-api'
import { useGetPostsQuery } from '../../redux/rtk-query/post-api'
import { detailProduct } from '../../redux/slices/productSlices'
import styles from './advertisement.module.css'

const Advertisement = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.users)

	const [page, setPage] = useState(1)
	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(true)

	const { data: favorites } = useFavoritesQuery(
		user?._id ? user?._id : skipToken
	)
	const { data, isFetching, isLoading } = useGetPostsQuery(page)

	const [addFavorites] = useAddFavoritesMutation()
	const [deleteFavorites] = useDeleteFavoritesMutation()

	const fetchMoreData = useCallback(() => {
		if (data?.results && !isFetching && !isLoading) {
			setList(prevList => [...prevList, ...data.results])
			if (list.length >= data.count) {
				setHasMore(false)
			} else {
				setPage(prevPage => prevPage + 1)
			}
		}
	}, [data, isFetching, isLoading, list.length])

	const handleFavoriteToggle = useCallback(
		async (id, isFavorite) => {
			if (user) {
				const requestData = { userId: user._id, adId: id }
				try {
					if (isFavorite) {
						await deleteFavorites(requestData)
						toast.info('Удалено')
					} else {
						await addFavorites(requestData)
						toast.info('Добавлено')
					}
				} catch (error) {
					console.error(error)
				}
			} else {
				navigate('/login')
			}
		},
		[user, addFavorites, deleteFavorites, navigate]
	)

	useEffect(() => {
		fetchMoreData()
	}, []) // Запуск при монтировании

	useEffect(() => {
		setPage(1)
		setList([])
		setHasMore(true)
	}, [location.pathname])

	const handleProductClick = id => {
		dispatch(detailProduct(id))
		navigate(`/product/${id}`)
	}

	const isDataEmpty = !data || data.count === 0

	return (
		<div className={styles.main}>
			<div className={styles.advertisment}>
				<h1 className={styles.title}>Рекомендации для вас</h1>
				<div>
					{isLoading ? (
						<div className={styles.flex}>
							{Array.from({ length: 4 }).map((_, index) => (
								<Skeleton
									key={index}
									animation='wave'
									variant='circular'
									className={styles.block2}
								/>
							))}
						</div>
					) : (
						<InfiniteScroll
							pageStart={0}
							loadMore={fetchMoreData}
							hasMore={hasMore}
							threshold={200}
							loader={
								<div key={0} className={styles.circul}>
									<CircularProgress color='info' size={30} />
								</div>
							}
						>
							<div className={styles.flex}>
								{!isDataEmpty &&
									list.map(item => {
										const isFavorite =
											user && favorites?.some(fav => fav.adId === item.adsId)

										return (
											<div key={item.adsId} className={styles.block}>
												<img
													onClick={() => handleProductClick(item.adsId)}
													className={styles.img}
													src={item.imagesUrl[0]}
													alt={item.title}
													loading='lazy'
													sizes='(max-width: 600px) 100vw, (min-width: 601px) 50vw'
													srcSet={`${item.imagesUrl[0]} 300w, ${item.imagesUrl[0]} 300w, ${item.imagesUrl[0]} 350w`}
												/>
												<div className={styles.infoBlock}>
													<div
														onClick={() => handleProductClick(item.adsId)}
														className={styles.info}
													>
														<p className={styles.subTitle}>{item.title}</p>
														<p className={styles.price}>
															{item.sum === 0
																? 'Договорная'
																: item.sum.toLocaleString('ru-RU')}
															{item.sum !== 0 && '₽'}
														</p>
														<p className={styles.metro}>
															<span
																className={styles.color}
																style={{
																	backgroundColor: item.metro[0]?.color,
																}}
															/>
															<span>{item.metro[0]?.name}</span>
														</p>
														<p className={styles.createdAt}>
															{new Date(item.createdAt).toLocaleString(
																'ru-RU',
																{
																	day: 'numeric',
																	month: 'long',
																	hour: '2-digit',
																	minute: '2-digit',
																}
															)}
														</p>
													</div>
													<button
														type='button'
														onClick={() =>
															handleFavoriteToggle(item.adsId, isFavorite)
														}
														className={
															isFavorite ? styles.button2 : styles.button
														}
													>
														{isFavorite ? <AiFillHeart /> : <AiOutlineHeart />}
													</button>
												</div>
											</div>
										)
									})}
							</div>
						</InfiniteScroll>
					)}
				</div>
			</div>
		</div>
	)
}

export default Advertisement
