import { api } from './setting'
export const telegramHelpApi = api.injectEndpoints({
	endpoints: build => ({
		telegramHelp: build.mutation({
			query: data => ({
				url: 'telegram-message',
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['post'],
		}),
	}),
})
export const { useTelegramHelpMutation } = telegramHelpApi
