import React from 'react'
import MainSearch from '../../components/home-components/main-search'
import PostCategory from '../../components/post-id/post-category'
import PostFilter from '../../components/post-id/post-filter'
import PostList from '../../components/post-id/post-list/post-list'
import { themes } from '../../utils/themes'
const ProductPage = () => {
	return (
		<div style={themes.container}>
			<MainSearch />
			<PostCategory />
			<PostFilter />
			<PostList />
		</div>
	)
}

export default ProductPage
