//1
import React, { useCallback, useRef } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { getSearchText } from '../../../redux/slices/productSlices'
import styles from './main-search.module.css'

const MainSearch = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const inputRef = useRef(null)

	const handleSubmit = useCallback(
		e => {
			e.preventDefault()
			const searchText = inputRef.current.value.trim()

			if (searchText) {
				dispatch(getSearchText(searchText))
				navigate(`/search/${searchText}`)
			}
		},
		[dispatch, navigate]
	)

	return (
		<div className={styles.mainSearch}>
			<form onSubmit={handleSubmit} className={styles.searchForm}>
				<span className={styles.icon}>
					<FiSearch />
				</span>
				<input
					ref={inputRef}
					className={styles.input}
					type='text'
					placeholder='Поиск по объявлениям'
				/>
				<button type='submit' className={styles.submit}>
					Поиск
				</button>
			</form>
		</div>
	)
}

export default React.memo(MainSearch)
