import { configureStore } from '@reduxjs/toolkit'
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { metroApi } from '../redux/rtk-query/metro-api'
import { postApi } from '../redux/rtk-query/post-api'
import { api } from '../redux/rtk-query/setting'
import { telegramHelpApi } from '../redux/rtk-query/telegram-hepl-api'
import { rootReducer } from '../redux/slices/root-reducer'

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['product', 'api', 'metro', 'telegramHelp'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV === 'development',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(
			api.middleware,
			metroApi.middleware,
			postApi.middleware,
			telegramHelpApi.middleware
		),
})

export const persistor = persistStore(store)
