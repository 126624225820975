import React from 'react'
import MyPost from '../../components/post/my-post'

const MyPostPage = () => {
	return (
		<div>
			<MyPost />
		</div>
	)
}

export default MyPostPage
