//1
import { ThemeProvider } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import MainPage from './pages/main-page'
import { THEME } from './utils/styles'
function App() {
	console.log = console.warn = console.error = function () {}
	const { pathname } = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])
	return (
		<div className='App'>
			<ThemeProvider theme={THEME}>
				<MainPage />
				<ToastContainer
					position='top-right'
					autoClose={1000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover
					theme='light'
				/>
			</ThemeProvider>
		</div>
	)
}

export default App
