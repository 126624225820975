//1
import Skeleton from '@mui/material/Skeleton'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useGetSubcategoryQuery } from '../../../redux/rtk-query/category-api'
import {
	getMetroFilter,
	getParentCategoryId,
	getParentLink,
} from '../../../redux/slices/productSlices'
import styles from './post-category.module.css'

const PostCategory = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [active, setActive] = useState('')
	const { categoryId } = useSelector(state => state.product)
	const { data: subCategory, isLoading } = useGetSubcategoryQuery(categoryId.id)

	const handleClick = (id, link, title) => {
		dispatch(getParentCategoryId({ id: id, title: title, link: link }))
		dispatch(getParentLink(link))
		dispatch(getMetroFilter([]))
		setActive(title) // Установка активного элемента
		navigate(`/${categoryId.link}/${link}`)
	}

	const start = 7
	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>{`${categoryId?.title} | TezJarnama`}</title>
				<meta
					name='description'
					content={`Просмотрите товары в категории ${categoryId?.title} на TezJarnama.`}
				/>
				<meta
					property='og:title'
					content={`${categoryId?.title} | TezJarnama`}
				/>
				<meta
					property='og:description'
					content={`Просмотрите товары в категории ${categoryId?.title} на TezJarnama.`}
				/>
				<meta property='og:type' content='website' />
				<meta
					property='og:url'
					content={`https://tezjarnama.ru/${categoryId?.link}`}
				/>
				<meta property='og:type' content='website' />
				<meta property='og:site_name' content='TezJarnama' />
			</Helmet>
			<h1 className={styles.title}>{categoryId.title}</h1>
			<h3 className={styles.link}>
				<Link to='/'>Главная </Link>/{' '}
				<Link to='/post/:name'>{categoryId.title}</Link>
			</h3>
			<div className={styles.main}>
				{isLoading
					? Array.from({ length: start }, (_, index) => (
							<Skeleton
								key={index} // Добавьте ключ для списка
								animation='wave'
								variant='circular'
								className={styles.block2}
							/>
					  ))
					: subCategory &&
					  subCategory.map(elem => (
							<div
								key={elem._id}
								className={`${styles.block} ${
									active === elem.parentTitle_ru ? styles.active : ''
								}`}
								onClick={() =>
									handleClick(
										elem._id,
										elem.parentTitle_en,
										elem.parentTitle_ru
									)
								}
							>
								<h1 className={styles.subTitle}>{elem.parentTitle_ru}</h1>
							</div>
					  ))}
			</div>
		</div>
	)
}

export default PostCategory
