//1
import CircularProgress from '@mui/material/CircularProgress'
import { skipToken } from '@reduxjs/toolkit/query/react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import img from '../../assets/icon 3d/search.png'
import MainSearch from '../../components/home-components/main-search/main-search'
import PostFilter from '../../components/post-id/post-filter'
import {
	useAddFavoritesMutation,
	useDeleteFavoritesMutation,
	useFavoritesQuery,
} from '../../redux/rtk-query/favorites-api'
import { useGetSearchQuery } from '../../redux/rtk-query/post-api'
import {
	detailProduct,
	getMaxPrice,
	getMetroFilter,
	getMinPrice,
	setSortOption,
} from '../../redux/slices/productSlices'
import { themes } from '../../utils/themes'
import styles from './search-page.module.css'
const SearchPage = () => {
	const { pathname } = useLocation()
	const { searchText, minPrice, maxPrice, metro, sortOption } = useSelector(
		state => state.product
	)
	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const { user } = useSelector(state => state.users)
	const { data: favorites } = useFavoritesQuery(
		user?._id ? user?._id : skipToken
	)
	const [addFavorites] = useAddFavoritesMutation()
	const [deleteFavorites] = useDeleteFavoritesMutation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [queryParams, setQueryParams] = useState({
		title: searchText,
		page: 1,
		minPrice: minPrice || undefined,
		maxPrice: maxPrice || undefined,
		metro: metro.length > 0 ? metro.map(el => el.name) : undefined,
		sortBy: sortOption,
	})

	const { data, isLoading, isFetching } = useGetSearchQuery(queryParams)
	useEffect(() => {
		const newQueryParams = {
			title: searchText,
			page: 1,
			minPrice: minPrice || undefined,
			maxPrice: maxPrice || undefined,
			metro: metro.length > 0 ? metro.map(el => el.name) : undefined,
			sortBy: sortOption,
		}
		setQueryParams(newQueryParams)
		setHasMore(true)
		setList([])
	}, [searchText, minPrice, maxPrice, metro, sortOption])

	useEffect(() => {
		dispatch(getMaxPrice(''))
		dispatch(getMetroFilter([]))
		dispatch(getMinPrice(''))
		dispatch(setSortOption('default'))
	}, [pathname])

	const fetchMoreData = () => {
		if (data?.results && !isFetching && !isLoading) {
			setList(prevList => [...prevList, ...data.results])
			if (data.pages > queryParams.page) {
				setQueryParams(prev => ({ ...prev, page: queryParams.page + 1 }))
			} else {
				setHasMore(false)
			}
		}
	}

	const handleFavorites = async (id, action) => {
		if (user) {
			const data = { userId: user._id, adId: id }
			try {
				if (action === 'add') {
					toast.info('Добавлено')
					await addFavorites(data)
				} else {
					toast.info('Удалено')
					await deleteFavorites(data)
				}
			} catch (e) {
				console.error(e)
			}
		} else {
			navigate('/login')
		}
	}

	return (
		<div style={themes.container}>
			<Helmet>
				<title>{`Поиск: ${searchText} | TezJarnama`}</title>
				<meta
					name='description'
					content={`Ищите и находите товары по запросу "${searchText}" на TezJarnama. Обширный выбор товаров, от электроники до одежды, ждет вас. Быстро и легко найдите то, что вам нужно.`}
				/>
				<meta
					property='og:title'
					content={`Поиск: ${searchText} | TezJarnama`}
				/>
				<meta
					property='og:description'
					content={`Ищите и находите товары по запросу "${searchText}" на TezJarnama. Обширный выбор товаров, от электроники до одежды, ждет вас. Быстро и легко найдите то, что вам нужно.`}
				/>
				<meta property='og:image' content='/path-to-your-search-image.jpg' />
				<meta property='og:type' content='website' />
				<meta
					property='og:url'
					content={`https://tezjarnama.ru/search?q=${encodeURIComponent(
						searchText
					)}`}
				/>
				<meta property='og:site_name' content='TezJarnama' />
				<meta property='og:type' content='website' />
			</Helmet>
			<MainSearch />
			{data?.total === 0 ? (
				<div className={styles.error}>
					<img
						className={styles.imgError}
						src={img}
						alt='Error'
						loading='lazy'
					/>
					<h3 className={styles.errorTitle}>К сожалению, ничего не найдено</h3>
					<p className={styles.errorText}>
						Мы не нашли то, что вы искали. Измените условия поиска.
					</p>
				</div>
			) : (
				<InfiniteScroll
					pageStart={0}
					loadMore={fetchMoreData}
					hasMore={hasMore}
					threshold={300}
					loader={
						<div key={0} className={styles.circul}>
							<CircularProgress color='info' size={30} />
						</div>
					}
				>
					<div>
						<h1 className={styles.title}>
							Объявления по запросу «{searchText}»
						</h1>
						<PostFilter />
						<div className={styles.flex}>
							{list?.map((elem, index) => (
								<div key={elem._id || index} className={styles.block}>
									<img
										onClick={() =>
											dispatch(detailProduct(elem._id)) &&
											navigate(`/product/${elem._id}`)
										}
										className={styles.img}
										loading='lazy'
										src={elem?.imagesUrl[0]}
										alt={elem?.title}
									/>
									<div className={styles.infoBlock}>
										<div
											onClick={() =>
												dispatch(detailProduct(elem._id)) &&
												navigate(`/product/${elem._id}`)
											}
											className={styles.info}
										>
											<p className={styles.subTitle}>{elem?.title}</p>
											<p className={styles.price}>
												{elem?.sum.toLocaleString('ru-RU')}₽
											</p>
											<p className={styles.metro}>
												<span
													className={styles.color}
													style={{ backgroundColor: elem?.metro[0].color }}
												/>
												<span>{elem?.metro[0].name}</span>
											</p>
											<p className={styles.createdAt}>
												{new Date(elem.createdAt).toLocaleString('ru-RU', {
													day: 'numeric',
													month: 'long',
													hour: '2-digit',
													minute: '2-digit',
												})}
											</p>
										</div>
										<button
											type='button'
											onClick={() =>
												handleFavorites(
													elem._id,
													favorites?.some(fav => fav?.adId === elem._id)
														? 'delete'
														: 'add'
												)
											}
											className={
												favorites?.some(fav => fav?.adId === elem._id)
													? styles.button2
													: styles.button
											}
										>
											{favorites?.some(fav => fav?.adId === elem._id) ? (
												<AiFillHeart />
											) : (
												<AiOutlineHeart />
											)}
										</button>
									</div>
								</div>
							))}
						</div>
					</div>
				</InfiniteScroll>
			)}
		</div>
	)
}

export default SearchPage
