import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	profile: false,
	storage: {},
}
const logicSlices = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		boolProfile: (state, action) => {
			state.profile = action.payload
		},
		setStorage: (state, action) => {
			state.storage = action.payload
		},
	},
})
export const { boolProfile, setStorage } = logicSlices.actions
export default logicSlices.reducer
