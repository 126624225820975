//1
import CircularProgress from '@mui/material/CircularProgress'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import {
	useConfirmRegisterMutation,
	useGetMeQuery,
	useLoginMutation,
} from '../../../redux/rtk-query/user-api'
import { setUser } from '../../../redux/slices/userSlices'
import { themes } from '../../../utils/themes'
import styles from './code-email.module.css'

const CodeEmail = () => {
	const { storage } = useSelector(state => state.logic)
	const [confirmRegister, { isLoading }] = useConfirmRegisterMutation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [code, setCode] = useState(['', '', '', ''])
	const confirmCode = code.join('')
	const [isLogin, setIsLogin] = useState(false)
	const inputsRef = useRef([])
	const [login] = useLoginMutation()
	const { refetch: getMeRefetch, data } = useGetMeQuery()

	// Обработчик изменения ввода
	const handleChange = useCallback(
		(index, event) => {
			const value = event.target.value
			const newCode = [...code]
			newCode[index] = value
			setCode(newCode)

			if (value.length > 0 && index < 3) {
				inputsRef.current[index + 1].focus()
			}
		},
		[code]
	)

	// Обработчик клавиши Backspace
	const handleKeyDown = useCallback(
		(index, event) => {
			if (event.key === 'Backspace' && !code[index] && index > 0) {
				inputsRef.current[index - 1].focus()
			}
		},
		[code]
	)

	// Регистрация
	const register = useCallback(async () => {
		const { email, password } = storage
		try {
			const res = await confirmRegister({
				email,
				code: confirmCode,
			}).unwrap()
			if (res) {
				const response = await login({ email, password })
				if (response?.data?.token) {
					Cookies.set('token', response.data.token)
					await getMeRefetch()
					setIsLogin(true)
				}
			}
		} catch (e) {
			toast.error(e?.data?.message)
			setCode(['', '', '', ''])
		}
	}, [confirmCode, confirmRegister, login, storage, getMeRefetch])

	// Обработка успешного логина
	useEffect(() => {
		if (isLogin && data) {
			dispatch(setUser(data))
			navigate('/')
			setIsLogin(false)
		}
	}, [isLogin, data, dispatch, navigate])

	return (
		<div className={styles.wrapper} style={themes.container}>
			<h1 className={styles.title}>Подтверждение кода</h1>
			<div className={styles.mainCode}>
				<h4 className={styles.subTitle}>Введите код из почты</h4>
				<div className={styles.codeBlock}>
					{code.map((digit, index) => (
						<input
							style={{
								borderColor: confirmCode.trim().length < 4 ? 'red' : '#0468FF',
							}}
							key={index}
							className={styles.code}
							type='number'
							value={digit}
							onChange={event => handleChange(index, event)}
							onKeyDown={event => handleKeyDown(index, event)}
							maxLength={1}
							ref={input => (inputsRef.current[index] = input)}
						/>
					))}
				</div>

				<div className={styles.blockBtn}>
					{isLoading ? (
						<button className={styles.btn}>
							<CircularProgress color='inherit' size={30} />
						</button>
					) : (
						<button
							disabled={confirmCode.trim().length < 4}
							style={{
								backgroundColor:
									confirmCode.trim().length < 4 ? '#d4e6fa' : '#0468FF',
								color: confirmCode.trim().length < 4 ? '#0468FF' : '#FFF',
								cursor: confirmCode.trim().length < 4 ? 'default' : 'pointer',
							}}
							onClick={register}
							className={styles.btn}
						>
							Подтвердить
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default CodeEmail
