import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
	useGetMetroQuery,
	useSearchMetroQuery,
} from '../../../redux/rtk-query/metro-api'
import {
	getMaxPrice,
	getMetroFilter,
	getMinPrice,
	setSortOption,
} from '../../../redux/slices/productSlices'
import FilterMetro from '../filter-metro/modal-metro'
import ModalMetroMobile from '../filter-metro/modal-metro-mobile/modal-metro-mobile'
import styles from './post-filter.module.css'
const PostFilter = () => {
	const location = useLocation()
	const dispatch = useDispatch()
	const [sorting, setSorting] = useState('')
	const [sumMin, setSumMin] = useState('')
	const [sumMax, setSumMax] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [open, setOpen] = useState(true)
	const [metroBoll, setMetroBool] = useState(false)
	const { data: metros } = useSearchMetroQuery(searchValue)
	const [active, setActive] = useState(false)
	const { data: station } = useGetMetroQuery()
	const [metro, setMetro] = useState([])
	const [width, setWidth] = useState(window.innerWidth)
	const sort = [
		{ name: 'По умолчании', setName: 'default' },
		{ name: 'Новые', setName: 'date' },
		{ name: 'Дешевле', setName: 'cheaper' },
		{ name: 'Дороже', setName: 'expensive' },
	]

	const handleChange = event => {
		setSorting(event.target.value)
	}
	useEffect(() => {
		const handleResize = event => {
			setWidth(event.target.innerWidth)
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const onFilter = e => {
		e.preventDefault()
		dispatch(getMetroFilter(metro))
		dispatch(getMinPrice(sumMin))
		dispatch(getMaxPrice(sumMax))
		dispatch(setSortOption(sorting))
		setActive(true)
	}
	const onReset = e => {
		e.preventDefault()
		dispatch(getMetroFilter([]))
		dispatch(getMinPrice(''))
		dispatch(getMaxPrice(''))
		dispatch(setSortOption('default'))
		setSorting('')
		setSumMin('')
		setSumMax('')
		setMetro([])
		setActive(false)
	}

	useEffect(() => {
		dispatch(getMaxPrice(''))
		dispatch(getMetroFilter([]))
		dispatch(getMinPrice(''))
		dispatch(setSortOption('default'))
		setSorting('')
		setMetro([])
		setSumMax('')
		setSumMin('')
		setActive(false)
	}, [location.pathname, dispatch, getMinPrice, getMaxPrice])
	return (
		<div className={styles.wrapper}>
			<div className={styles.mobile}>
				<button className={styles.filter} onClick={() => setOpen(!open)}>
					{open ? 'Фильтр' : 'Закрыть'}
				</button>
			</div>

			<div className={open ? styles.active : styles.none}>
				<form className={styles.form}>
					<div className={styles.blockForm}>
						<label className={styles.label}>
							<h4 className={styles.title}>Цена</h4>
							<div className={styles.blockPrice}>
								<input
									value={sumMin}
									onChange={e => setSumMin(e.target.value)}
									className={styles.inputPrice}
									type='number'
									placeholder='Цена от'
								/>
								<input
									value={sumMax}
									onChange={e => setSumMax(e.target.value)}
									className={styles.inputPrice}
									type='number'
									placeholder='Цена до'
								/>
							</div>
						</label>
						<label className={`${styles.label} ${styles.labelMetro}`}>
							<h4 className={styles.title}>Метро</h4>
							<div className={styles.metro}>
								<button
									onClick={() => setMetroBool(true)}
									style={
										metro.length > 0
											? {
													backgroundColor: '#0468FF',
													color: 'white',
													borderColor: '#0468FF',
											  }
											: null
									}
									type='button'
									className={styles.btnMetro}
								>
									{metro.length > 0 ? 'Изменить' : 'Выбрать'}
								</button>
							</div>
						</label>
						<label className={styles.label}>
							<h4 className={styles.title}>Сортировка</h4>
							<Select
								className={styles.select}
								displayEmpty
								value={sorting}
								onChange={handleChange}
								input={<OutlinedInput />}
								renderValue={selected => {
									if (selected.length === 0) {
										return <p className={styles.selectText}>По умолчании</p>
									}
									return selected === 'default'
										? 'По умолчанию'
										: selected === 'date'
										? 'Новые'
										: selected === 'cheaper'
										? 'Дешевле'
										: selected === 'expensive'
										? 'Дороже'
										: 'По умолчанию'
								}}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								{sort.map(name => (
									<MenuItem
										className={styles.selectText}
										key={name.setName}
										value={name.setName}
									>
										{name.name}
									</MenuItem>
								))}
							</Select>
						</label>
					</div>
					<div className={styles.blockSubmits}>
						<div className={styles.blockSubmit}>
							<button onClick={e => onFilter(e)} className={styles.submit}>
								Показать
							</button>
						</div>
						{active && (
							<div className={styles.blockSubmit}>
								<button onClick={e => onReset(e)} className={styles.submit2}>
									Сбросить
								</button>
							</div>
						)}
					</div>
				</form>
			</div>
			{width < 959
				? metroBoll && (
						<ModalMetroMobile
							open={open}
							metro={metro}
							search={searchValue}
							setSearch={setSearchValue}
							data={
								metros && metros.metros.length > 0 ? metros.metros : station
							}
							setMetro={setMetro}
							setClose={setMetroBool}
						/>
				  )
				: metroBoll && (
						<FilterMetro
							open={open}
							metro={metro}
							search={searchValue}
							setSearch={setSearchValue}
							data={
								metros && metros.metros.length > 0 ? metros.metros : station
							}
							setMetro={setMetro}
							setClose={setMetroBool}
						/>
				  )}
		</div>
	)
}

export default PostFilter
