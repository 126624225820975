import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { themes } from '../../../utils/themes'
import styles from './about.module.css'
const About = () => {
	return (
		<div style={themes.container}>
			<Helmet>
				<title>
					О нас | TezJarnama.ru - Платформа для размещения объявлений
				</title>
				<meta
					name='description'
					content='TezJarnama.ru — это платформа для размещения объявлений, где пользователи могут находить и публиковать предложения по недвижимости, автомобилям, электронике и другим товарам.'
				/>
				<meta
					name='keywords'
					content='о нас, TezJarnama, объявления, недвижимость, автомобили, электроника, платформа для объявлений'
				/>
				<meta property='og:title' content='О нас | TezJarnama.ru' />
				<meta
					property='og:description'
					content='Узнайте больше о TezJarnama.ru, платформе для размещения и поиска объявлений. Мы помогаем пользователям находить лучшие предложения.'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/about' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='О нас | TezJarnama.ru' />
				<meta
					name='twitter:description'
					content='TezJarnama.ru — это платформа, где пользователи могут легко размещать и находить объявления о продаже товаров и услуг.'
				/>
			</Helmet>
			<h1 className={styles.title}>О проекте</h1>
			<div className={styles.main}>
				<h3 className={styles.subTitle}>
					Добро пожаловать на наш сайт, Мы создали уникальную платформу, где
					каждый может бесплатно размещать объявления о продаже или покупке
					самых разнообразных товаров и услуг.
				</h3>
				<p className={styles.subText}>Что Вы Можете Найти на Нашем Сайте:</p>
				<ol className={styles.list}>
					<li>
						• Лишние Вещи: Превратите ненужные вещи в деньги, размещая
						объявления о продаже подержанных товаров.
					</li>
					<li>
						• Электроника: Покупайте и продавайте электронные устройства, от
						мобильных телефонов до компьютерной техники.
					</li>
					<li>
						• Автомобили: Найдите свой следующий автомобиль или продайте старый.
						От легковых авто до грузовиков и спецтехники.
					</li>
					<li>
						• Дом и Дача: Всё для уюта вашего дома и сада, от мебели до садового
						инвентаря.
					</li>
					<li>
						• Товары для Детей: Всё для ваших малышей, от одежды до игрушек.
					</li>
					<li>
						• Хобби и Отдых: Товары для спорта, туризма и различных увлечений.
					</li>
					<li>
						• Работа для Бизнеса: Объявления о вакансиях, а также товары и
						услуги для вашего бизнеса.
					</li>
					<li>
						• Ручная Работа: Покупайте и продавайте уникальные изделия ручной
						работы.
					</li>
					<li>• Услуги: Различные услуги от частных лиц и компаний.</li>
				</ol>
				<p className={styles.text}>
					Мы стремимся предоставить удобную и безопасную платформу для
					купли-продажи товаров и услуг. Наша миссия – сделать процесс продажи и
					покупки максимально простым и доступным для каждого.
				</p>
				<p className={styles.text}>
					Спасибо, что выбрали нас для реализации ваших потребностей в покупке и
					продаже товаров и услуг!
				</p>
				<h2 className={styles.link}>
					Есть вопрос? Нужно связаться с администрацией? Вам сюда:{' '}
					<Link to='/feedback'>Обратная связь</Link> (техподдержка)
				</h2>
			</div>
		</div>
	)
}

export default About
