import React from 'react'
import { Link } from 'react-router-dom'
import img from '../../../../assets/icon 3d/favorite-folder.png'
import { themes } from '../../../../utils/themes'
import styles from './no-favorites.module.css'

const NoFavorites = () => {
	return (
		<div style={themes.container} className={styles.main}>
			<img className={styles.img} src={img} alt='tezjarnama избранное' loading="lazy"/>
			<h1 className={styles.title}>Добавляйте объявления в избранное</h1>
			<Link className={styles.btn} to={'/'}>
				Искать объявление
			</Link>
		</div>
	)
}

export default NoFavorites
