import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	detail: '',
	detail2: '',
	categoryId: {},
	parentCategoryId: {},
	parentLink: '',
	secondCategory: {},
	searchText: '',
	metro: [],
	sortOption: 'default',
	minPrice: '',
	maxPrice: '',
	deletePostId: '',
}
const productSlices = createSlice({
	name: 'product',
	initialState,
	reducers: {
		detailProduct: (state, action) => {
			state.detail = action.payload
		},
		detailProduct2: (state, action) => {
			state.detail2 = action.payload
		},
		getCategoryId: (state, action) => {
			state.categoryId = action.payload
		},
		getParentCategoryId: (state, action) => {
			state.parentCategoryId = action.payload
		},
		getParentLink: (state, action) => {
			state.parentLink = action.payload
		},
		getSecondCategoryId: (state, action) => {
			state.secondCategory = action.payload
		},
		getSearchText: (state, action) => {
			state.searchText = action.payload
		},
		getMetroFilter: (state, action) => {
			state.metro = action.payload
		},
		getMinPrice: (state, action) => {
			state.minPrice = action.payload
		},
		getMaxPrice: (state, action) => {
			state.maxPrice = action.payload
		},
		onDeletePost: (state, action) => {
			state.deletePostId = action.payload
		},
		setSortOption: (state, action) => {
			state.sortOption = action.payload
		},
	},
})
export const {
	detailProduct,
	detailProduct2,
	getCategoryId,
	getParentCategoryId,
	getSecondCategoryId,
	getSearchText,
	getMetroFilter,
	getParentLink,
	getMinPrice,
	getMaxPrice,
	onDeletePost,
	setSortOption,
} = productSlices.actions
export default productSlices.reducer
