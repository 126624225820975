//1
import React from 'react'
import { HiHeart, HiHome, HiMiniSquaresPlus, HiUser } from 'react-icons/hi2'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styles from './nav-mobile.module.css'

const NavMobile = () => {
	// Получаем данные о пользователе из Redux
	const user = useSelector(state => state.users.user)
	// Получаем текущее местоположение
	const location = useLocation()

	// Функция для проверки, является ли текущий путь активным
	const isActive = path => location.pathname === path

	// Функция для рендеринга ссылки
	const renderLink = (path, icon, label, isUserDependent = false) => (
		<div>
			<Link
				// Если ссылка зависит от состояния пользователя и пользователь не авторизован, перенаправляем на /login
				to={isUserDependent && !user ? '/login' : path}
				className={`${styles.block} ${
					isActive(path) ? styles.active : styles.block
				}`}
			>
				<span className={styles.navIcon}>{icon}</span>
				<p>{label}</p>
			</Link>
		</div>
	)

	return (
		<div className={styles.mobileNav}>
			{/* Рендеринг ссылок с использованием функции renderLink */}
			{renderLink('/', <HiHome />, 'Главная')}
			{renderLink('/favorites', <HiHeart />, 'Избранное', true)}
			{renderLink('/addPost', <HiMiniSquaresPlus />, 'Объявления', true)}
			{user
				? renderLink(
						'/profile',
						<span className={styles.name}>{user.fullName[0]}</span>,
						'Профиль'
				  )
				: renderLink('/login', <HiUser />, 'Профиль')}
		</div>
	)
}

export default React.memo(NavMobile)
