import { api } from './setting'

export const favoritesApi = api.injectEndpoints({
	endpoints: build => ({
		addFavorites: build.mutation({
			query: data => ({
				url: `/user/${data.userId}/favorites/${data.adId}`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['favorites'],
		}),
		favorites: build.query({
			query: id => ({
				url: `user/${id}/favorites`,
			}),
			providesTags: ['favorites'],
		}),
		deleteFavorites: build.mutation({
			query: data => ({
				url: `user/${data.userId}/favoritesdelete/${data.adId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['favorites'],
		}),
	}),
})
export const {
	useAddFavoritesMutation,
	useFavoritesQuery,
	useDeleteFavoritesMutation,
} = favoritesApi
