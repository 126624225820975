import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useTelegramHelpMutation } from '../../../redux/rtk-query/telegram-hepl-api'
import { themes } from '../../../utils/themes'
import styles from './feedback.module.css'
const Feedback = () => {
	const [telegramHelp, { isLoading }] =
		useTelegramHelpMutation()
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm()
	const onSubmit = async data => {
		try {
			toast.success('Отправлено')
			await telegramHelp(data)
			reset()
		} catch (e) {
			toast.error('Ошибка')
		}
	}
	return (
		<div style={themes.container}>
			<Helmet>
				<title>Обратная связь | TezJarnama.ru - Свяжитесь с нами</title>
				<meta
					name='description'
					content='Свяжитесь с командой TezJarnama.ru. Мы всегда готовы помочь вам с любыми вопросами, связанными с размещением объявлений и использованием нашего сервиса.'
				/>
				<meta
					name='keywords'
					content='обратная связь, контакт, TezJarnama, поддержка, помощь, размещение объявлений'
				/>
				<meta property='og:title' content='Обратная связь | TezJarnama.ru' />
				<meta
					property='og:description'
					content='Если у вас есть вопросы или предложения, свяжитесь с нами через страницу обратной связи TezJarnama.ru.'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/feedback' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='Обратная связь | TezJarnama.ru' />
				<meta
					name='twitter:description'
					content='Свяжитесь с нами, если у вас есть вопросы или предложения по использованию TezJarnama.ru.'
				/>
			</Helmet>
			<h1 className={styles.title}>Обратная связь</h1>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<label className={styles.label}>
					<p className={styles.text}>Сообщение</p>
					<textarea
						className={styles.textarea}
						{...register('text', { required: true })}
						aria-invalid={errors.text ? 'true' : 'false'}
						placeholder='Введите текст'
					/>
					{errors.text?.type === 'required' && (
						<p className={styles.error} role='alert'>
							Обязательное поле
						</p>
					)}
				</label>
				<label className={styles.label}>
					<p className={styles.text}>e-mail</p>
					<input
						className={styles.input}
						type='text'
						placeholder='Введите email'
						{...register('email', {
							required: true,
							pattern:
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						})}
						aria-invalid={errors.email ? 'true' : 'false'}
					/>
					{errors.email && (
						<p className={styles.error}>Email в формате: name@email.com</p>
					)}
				</label>
				<button type='submit' className={styles.btn}>
					{isLoading ? (
						<CircularProgress size={25} color={'inherit'} />
					) : (
						'Отправить сообщение'
					)}
				</button>
			</form>
		</div>
	)
}

export default Feedback
