import React from 'react'
import {themes} from '../../utils/themes'
import img from '../../assets/icon 3d/404.png'
import { Link } from 'react-router-dom'
import styles from './error-404.module.css'
const Error404 = () => {
  return (
	<div className={styles.wrapper} style={themes.container}>
	  <div className={styles.main}>
		<img className={styles.img} src={img} alt='tezjarnama.ru' loading="lazy"/>
		<div  className={styles.block}>
			<h3 className={styles.title}>Такой страницы нe существует</h3>
			<p className={styles.text}>Одно из двух: или кто-то её удалил, или в ссылке опечатка.</p>
			<Link className={styles.link} to='/'>На главную</Link>
		</div>
	  </div>
	</div>
  )
}

export default Error404
