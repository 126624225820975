//1
import React from 'react'
import { Helmet } from 'react-helmet'
import { AiFillHeart } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import NoFavorites from '../../components/home-components/favorites-components/no-favorites'
import {
	useDeleteFavoritesMutation,
	useFavoritesQuery,
} from '../../redux/rtk-query/favorites-api'
import { detailProduct } from '../../redux/slices/productSlices'
import { themes } from '../../utils/themes'
import LoadingPage from '../error-page/loadingPage'
import styles from './favorites-page.module.css'

const FavoritesPage = () => {
	const description =
		'Просмотрите и управьте своими избранными объявлениями на TezJarnama. Сохраните интересные предложения, чтобы быстро вернуться к ним позже. В вашем списке избранного вы найдете все объявления, которые вы добавили в избранное.'

	const { user } = useSelector(state => state.users)
	const { data: favorites, isLoading } = useFavoritesQuery(user?._id)
	const [deleteFavorites] = useDeleteFavoritesMutation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const handleProductDetails = id => {
		dispatch(detailProduct(id))
		navigate(`/product/${id}`)
	}

	const handleFavoriteToggle = async id => {
		const payload = { userId: user._id, adId: id }
		try {
			await deleteFavorites(payload)
			toast.error('Удалено из избранного')
		} catch (error) {
			console.error(error)
		}
	}

	if (isLoading) {
		return <LoadingPage />
	}

	return (
		<div style={themes.container}>
			<Helmet>
				<title>Избранное | TezJarnama</title>
				<meta name='description' content={description} />
				<meta property='og:title' content='Избранное | TezJarnama' />
				<meta property='og:description' content={description} />
				<meta property='og:image' content='/public/logo.png' />
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://tezjarnama.ru/favorites' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content='Избранное | TezJarnama' />
				<meta name='twitter:description' content={description} />
				<meta property='og:site_name' content='TezJarnama' />
				<meta property='og:type' content='website' />
			</Helmet>
			<h1 className={styles.title}>Избранные объявления</h1>
			<div className={favorites?.length === 0 ? styles.main2 : styles.main}>
				{favorites && favorites?.length === 0 ? (
					<NoFavorites />
				) : (
					favorites &&
					favorites.map((elem, index) => (
						<div key={index} className={styles.block}>
							<img
								onClick={() => handleProductDetails(elem?.adId)}
								className={styles.img}
								src={elem?.imagesUrl[0]}
								alt={elem?.title}
								loading='lazy'
							/>
							<div className={styles.infoBlock}>
								<div
									onClick={() => handleProductDetails(elem?.adId)}
									className={styles.info}
								>
									<p className={styles.subTitle}>{elem?.title}</p>
									<p className={styles.price}>
										{elem?.sum.toLocaleString('ru-RU')}₽
									</p>
									<p className={styles.metro}>
										<span
											className={styles.color}
											style={{
												backgroundColor: `${elem?.metro[0]?.color}`,
											}}
										></span>
										<span>{elem?.metro[0]?.name}</span>
									</p>
									<p className={styles.createdAt}>
										{new Date(elem?.adCreationTime)?.toLocaleString('ru-RU', {
											day: 'numeric',
											month: 'long',
											hour: '2-digit',
											minute: '2-digit',
										})}
									</p>
								</div>
								<button
									type='button'
									onClick={() => handleFavoriteToggle(elem?.adId)}
									className={styles.button}
								>
									<AiFillHeart />
								</button>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default FavoritesPage
