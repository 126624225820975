import { api } from './setting'

export const postApi = api.injectEndpoints({
	endpoints: build => ({
		addPost: build.mutation({
			query: data => ({
				url: '/ads/add',
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['post'],
		}),
		getPosts: build.query({
			query: page => ({
				url: `/ads?limit=4&page=${page}`,
				method: 'GET',
			}),
		}),
		getDetailPost: build.query({
			query: id => ({
				url: `/ads/${id}`,
			}),
		}),
		myPost: build.query({
			query: id => ({
				url: `/ads/user/${id}`,
			}),
			providesTags: ['post'],
		}),
		changePost: build.mutation({
			query: ({ id, data }) => ({
				url: `/ads/update/${id}`,
				method: 'PATCH',
				body: data,
			}),
			invalidatesTags: ['post'],
		}),
		deletePost: build.mutation({
			query: id => ({
				url: `/ads/delete/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['post'],
		}),
		getPostCategory: build.query({
			query: data => ({
				url: `/ads/category/search?limit=4`,
				params: data,
			}),
		}),
		getSearch: build.query({
			query: data => ({
				url: `/ads/title/search?limit=4`,
				params: data,
			}),
		}),
		getSimilarPost: build.query({
			query: id => ({
				url: `/ads/${id}/similar`,
			}),
		}),
	}),
})
export const {
	useAddPostMutation,
	useGetPostsQuery,
	useMyPostQuery,
	useChangePostMutation,
	useDeletePostMutation,
	useGetPostCategoryQuery,
	useGetSearchQuery,
	useGetDetailPostQuery,
	useGetSimilarPostQuery,
} = postApi
