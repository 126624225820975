import CircularProgress from '@mui/material/CircularProgress'
import React, { useState } from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import kgzLAng from '../../../assets/images/language/kgz.png'
import rusLang from '../../../assets/images/language/rus.png'
import logo from '../../../assets/logo-vmake.jpg'
import { useGetMeQuery } from '../../../redux/rtk-query/user-api'
import { themes } from '../../../utils/themes'
import { skipToken } from '@reduxjs/toolkit/query/react';
import styles from './header.module.css'
const Header = () => {
	const { user } = useSelector(state => state.users)
	const { isLoading } = useGetMeQuery(user ? undefined : skipToken)
	const cityOption = [
		{ value: 'rus', label: <div className={styles.option}>Москва</div> },
		{
			value: 'kgz',
			label: <div className={styles.option}>Санкт-Петербург</div>,
		},
	]

	const languageOption = [
		{
			value: 'rus',
			label: (
				<div className={styles.option}>
					{' '}
					<img className={styles.iconLang} src={rusLang} alt={rusLang.name} loading="lazy"/>
					Русский
				</div>
			),
		},
		{
			value: 'kgz',
			label: (
				<div className={styles.option}>
					{' '}
					<img className={styles.iconLang} src={kgzLAng} alt={kgzLAng.name} loading="lazy"/>
					Кыргызча
				</div>
			),
		},
	]

	const [city, setCity] = useState(cityOption)
	const [language, setLanguage] = useState(languageOption)

	return (
		<div className={styles.main} style={themes.container}>
			<div className={styles.leftNav}>
				<Link className={styles.logo} to={'/'}>
					<img className={styles.logoImg} src={logo} alt='tezjarnama' loading="lazy"/>
					<h1 className={styles.logoText}>TezJarnama</h1>
				</Link>
				{/* <div className={styles.langBlock}>
          <Select
            defaultValue={city}
            onChange={setCity}
            options={cityOption}
            isSearchable={false}
            components={{
              DropdownIndicator: () => null,
            }}
          />
          <Select
            defaultValue={language}
            onChange={setLanguage}
            options={languageOption}
            isSearchable={false}
            components={{
              DropdownIndicator: () => null,
            }}
          />
        </div> */}
			</div>
			<div className={styles.rightNav}>
				<Link
					className={`${styles.nav} ${styles.icon} ${styles.iconHeard}`}
					to={user ? '/favorites' : '/login'}
				>
					<AiOutlineHeart />
				</Link>
				<Link
					className={`${styles.nav} ${styles.advertisement}`}
					to={user ? '/addPost' : '/login'}
				>
					+ Разместить объявление
				</Link>
				{user ? (
					<Link to={'/profile'} className={styles.profile}>
						<span className={styles.img}>{user && user.fullName[0]}</span>
						<span className={styles.profilInfo}>
							<span className={styles.name}>{user && user.fullName}</span>
						</span>
					</Link>
				) : isLoading ? (
					<CircularProgress color={'info'} size={30} />
				) : (
					<Link className={`${styles.nav} ${styles.login}`} to={'/login'}>
						Войти
					</Link>
				)}
			</div>
		</div>
	)
}

export default Header
