import { api } from './setting'

export const categoryApi = api.injectEndpoints({
	endpoints: build => ({
		getCategory: build.query({
			query: () => ({
				url: '/category',
			}),
		}),
		getSubcategory: build.query({
			query: id => ({
				url: `/parentcategory/${id}`,
			}),
		}),
		getSecondCategory: build.query({
			query: id => ({
				url: `/secondcategory/${id}`,
			}),
		}),
	}),
})
export const {
	useGetCategoryQuery,
	useGetSubcategoryQuery,
	useGetSecondCategoryQuery,
} = categoryApi
