import { api } from './setting'
export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    registration: build.mutation({
      query: (data) => ({
        url: '/auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    confirmRegister: build.mutation({
      query: (code) => ({
        url: '/auth/confirmEmail',
        method: 'POST',
        body: code,
      }),
    }),
    login: build.mutation({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        body: data,
      }),
    }),
    getMe: build.query({
      query: () => ({
        url: '/auth/getMe',
        method: 'GET'
      }),
    }),
    resetPassword: build.mutation({
      query: (email) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: email,
      }),
    }),
    resetPasswordCode: build.mutation({
      query: (data) => ({
        url: '/auth/confirm-reset-code',
        method: 'POST',
        body: data
      })
    }),
    newPassword: build.mutation({
      query: (data) => ({
        url: '/auth/reset-password/update',
        method: 'PATCH',
        body: data
      })
    })
  }),
})
export const {
  useRegistrationMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useConfirmRegisterMutation,
  useGetMeQuery,
    useResetPasswordCodeMutation,
    useNewPasswordMutation
} = userApi
