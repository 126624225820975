import React from 'react'
import img from '../../../assets/icon 3d/noPost.png'
import styles from './noPost.module.css'
const NoPost = () => {
	return (
		<div className={styles.wrapper}>
			<img className={styles.img} src={img} alt='Нет объявлений' loading="lazy"/>
			<h3 className={styles.title}>
				В данный момент в этой категории нет объявлений. Пожалуйста, проверьте
				позже или посмотрите другие категории на нашем сайте.
			</h3>
		</div>
	)
}

export default NoPost
