//1
import CircularProgress from '@mui/material/CircularProgress'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRegistrationMutation } from '../../../redux/rtk-query/user-api'
import { setStorage } from '../../../redux/slices/logicSlices'
import { themes } from '../../../utils/themes'
import styles from '../login/login.module.css'
const Register = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [registration, { isLoading }] = useRegistrationMutation()
	const [errorRegis, setErrorRegis] = useState(false)
	const [toggle, setToggle] = useState(true)

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm({
		defaultValues: {
			fullName: '',
			email: '',
			phoneNumber: '',
			password: '',
		},
	})

	// Мемоизация функции отправки формы
	const onSubmit = useCallback(
		async form => {
			const { password, fullName, phoneNumber, email } = form
			try {
				dispatch(setStorage(form))
				await registration({
					email,
					fullName,
					phoneNumber,
					password,
					role: 'buyer',
				}).unwrap()
				navigate('/codeEmail')
				setErrorRegis(false)
			} catch (e) {
				toast.error(e?.data?.message)
				if (e.status === 400) {
					setErrorRegis(true)
				}
			}
		},
		[registration, navigate, dispatch]
	)

	// Переключение видимости пароля
	const handleTogglePassword = useCallback(() => {
		setToggle(prev => !prev)
	}, [])

	return (
		<div style={themes.container} className={styles.login}>
			<Helmet>
				<title>Регистрация | TezJarnama.ru - Размещение объявлений</title>
				<meta
					name='description'
					content='Зарегистрируйтесь на TezJarnama.ru, чтобы размещать объявления о продаже недвижимости, автомобилей, электроники и других товаров.'
				/>
				<meta
					name='keywords'
					content='регистрация, TezJarnama, объявления, недвижимость, автомобили, электроника'
				/>
				<meta property='og:title' content='Регистрация | TezJarnama.ru' />
				<meta
					property='og:description'
					content='Присоединяйтесь к TezJarnama.ru и начинайте размещать свои объявления сегодня!'
				/>
				<meta property='og:url' content='https://tezjarnama.ru/register' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='Регистрация | TezJarnama.ru' />
				<meta
					name='twitter:description'
					content='Создайте аккаунт на TezJarnama.ru, чтобы размещать объявления и находить выгодные предложения.'
				/>
			</Helmet>
			<h1 className={styles.title}>Регистрация</h1>
			<div className={styles.block}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<label className={styles.label}>
						<p className={styles.subTitle}>
							Имя пользователя <span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('fullName', { required: true })}
							aria-invalid={errors.fullName ? 'true' : 'false'}
							placeholder={'Введите ваше имя'}
						/>
						{errors.fullName && (
							<p className={styles.error}>Обязательное поле</p>
						)}
					</label>

					<label className={styles.label}>
						<p className={styles.subTitle}>
							Почта<span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('email', {
								required: true,
								pattern:
									/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							})}
							aria-invalid={errors.email ? 'true' : 'false'}
							placeholder={'Введите email'}
							style={{ borderColor: errorRegis ? 'red' : '#D9EDFF' }}
						/>
						{errors.email && (
							<p className={styles.error}>Email в формате: name@email.com</p>
						)}
						{errorRegis && (
							<p className={styles.error}>Этот email уже зарегистрирован</p>
						)}
					</label>

					<label className={styles.label}>
						<p className={styles.subTitle}>
							Номер телефона<span>*</span>
						</p>
						<Controller
							name='phoneNumber'
							control={control}
							rules={{
								validate: value => isValidPhoneNumber(value),
								required: true,
							}}
							render={({ field: { onChange, value } }) => (
								<Input
									className={styles.input}
									country='RU'
									international
									withCountryCallingCode
									value={value}
									onChange={onChange}
									maxLength={16}
								/>
							)}
						/>
						{errors.phoneNumber && (
							<p className={styles.error}>Обязательное поле</p>
						)}
					</label>

					<label className={`${styles.label} ${styles.label2}`}>
						<p className={styles.subTitle}>
							Пароль<span>*</span>
						</p>
						<input
							className={styles.input}
							{...register('password', {
								required: 'Пароль обязателен',
								minLength: {
									value: 6,
									message: 'Пароль должен содержать минимум 6 символов',
								},
							})}
							aria-invalid={errors.password ? 'true' : 'false'}
							placeholder={'Введите пароль'}
							type={toggle ? 'password' : 'text'}
						/>
						<span onClick={handleTogglePassword} className={styles.toggle}>
							{toggle ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
						</span>
						{errors.password && (
							<p className={styles.error}>{errors.password.message}</p>
						)}
					</label>

					<p className={styles.question}>
						Уже есть аккаунт?{' '}
						<Link className={styles.linkRegister} to='/login'>
							Войдите
						</Link>
					</p>

					<div className={styles.blockBtn}>
						{isLoading ? (
							<button className={styles.submit}>
								<CircularProgress color='inherit' />
							</button>
						) : (
							<button className={styles.submit}>Зарегистрироваться</button>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

export default Register
