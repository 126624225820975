import CircularProgress from "@mui/material/CircularProgress"
import React from 'react'
import styles from './loading.module.css'
const LoadingPage = () => {
	return (
		<div className={styles.wrapper}>
			<CircularProgress color={'info'} />
		</div>
	)
}

export default LoadingPage
