import About from '../components/footer/about'
import Feedback from '../components/footer/feedback/feedback'
import Help from '../components/footer/help/help'
import CodeEmail from '../components/login-components/code-email'
import Login from '../components/login-components/login'
import ProfileMobile from '../components/login-components/profile-mobile'
import Register from '../components/login-components/register'
import ResetPassword from '../components/login-components/reset-password'
import ResetPasswordQuestion from '../components/login-components/reset-password-question'
import NewPassword from '../components/login-components/reset-password/newPassword/NewPassword'
import AddPost from '../components/post/add-post'
import ChangePost from '../components/post/change-post/change-post'
import DetailProduct from '../pages/dateail-page'
import Error404 from '../pages/error-page/error-404'
import FavoritesPage from '../pages/favorites-page/favorites-page'
import HomePage from '../pages/home-page'
import MyPostPage from '../pages/my-post-page/my-post-page'
import SearchPage from '../pages/search-page/search-page'
import SecondCategoryPage from '../pages/secondCategory-page/secondCategory-page'
import ProductPage from '../pages/subCategory-page/product-page'

export const routes = [
	{ path: '/', element: <HomePage /> },
	{ path: '/login', element: <Login /> },
	{ path: '/register', element: <Register /> },
	{ path: '/resetPassword', element: <ResetPasswordQuestion /> },
	{ path: '/favorites', element: <FavoritesPage /> },
	{ path: '/profile', element: <ProfileMobile /> },
	{ path: '/addPost', element: <AddPost /> },
	{ path: '/codeEmail', element: <CodeEmail /> },
	{ path: '/confirm-code', element: <ResetPassword /> },
	{ path: '/new-password', element: <NewPassword /> },
	{ path: '/product/:id', element: <DetailProduct /> },
	{ path: '/help', element: <Help /> },
	{ path: '/feedback', element: <Feedback /> },
	{ path: '/about', element: <About /> },
	{ path: 'post/:name', element: <ProductPage /> },
	{ path: '/:name/:subName', element: <SecondCategoryPage /> },
	{ path: '/:name/:subName/:parent', element: <SecondCategoryPage /> },
	{ path: '/myPost', element: <MyPostPage /> },
	{ path: '/changePost/:id', element: <ChangePost /> },
	{ path: '/search/:text', element: <SearchPage /> },
	{ path: '*', element: <Error404 /> },
]
