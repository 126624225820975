import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: headers => {
		const token = Cookies.get('token')
		if (token) {
			headers.set('authorization', `Bearer ${token}`)
		}
		return headers
	},
})
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 })
export const api = createApi({
	tagTypes: ['favorites', 'post'],
	reducerPath: 'api',
	baseQuery: baseQueryWithRetry,
	refetchOnMountOrArgChange: true,
	endpoints: () => ({}),
})
